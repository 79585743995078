import React, { useEffect, useState } from "react";
import styles from "./FreeBook.module.css";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import axios from "axios";

const FreeBook = ({ myRef }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("/send-free-book", {
        name: name,
        email: email,
        phoneNum: phoneNum,
        formType: "Free Book Request",
      })
      .then((response) => {
        if (response.data.msg === "success") {
          setError(false);
          setSuccess("Enjoy the free e-book!");
          setLoading(false);
          setEmail("");
          setName("");
          setPhoneNum("");
        } else if (response.data.msg === "fail") {
          setSuccess(false);
          setError("Email failed to send, try again by reloading the page.");
          setLoading(false);
          setEmail("");
          setName("");
          setPhoneNum("");
        }
      });
    const pdfUrl = "FreeBook.pdf";
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "FreeBook.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );
  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  return (
    <div
      ref={myRef}
      style={{
        backgroundImage: matches
          ? `url('/images/free_book_background.webp')`
          : `url('/images/free_book_background_mob.webp')`,
      }}
      className={styles.main}
    >
      <Container>
        <Row className="gy-3">
          <Col lg={7}>
            <Row className="gy-5">
              <Col sm={4} lg={4}>
                <div className={styles.imgCont}>
                  <img src="/images/ebook.png" />
                </div>
              </Col>
              <Col sm={8} lg={8}>
                <div className={styles.contentCont}>
                  <h1 className="h1_main">
                    FREE <span>E-BOOK</span>
                  </h1>
                  <p className="para_main">
                    Gain Exclusive Access to Our FREE eBook: "UNVEILING THE
                    CANADIAN DREAM" authored by RCIC Koneswaran Thurairasah.
                    Delve deep into enlightening content and enhance your
                    understanding, all without spending a dime.
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={5}>
            <form onSubmit={formSubmitHandler} className={styles.form}>
              {loading ? (
                <div className="row pb-3">
                  <div className="col-12">
                    <Spinner
                      animation="border"
                      role="status"
                      style={{ color: "white" }}
                    />
                  </div>
                </div>
              ) : success ? (
                <div className="row pb-3">
                  <div className="col-12">
                    <p className="para_main" style={{ color: "white" }}>
                      {success}
                    </p>
                  </div>
                </div>
              ) : (
                error && (
                  <div className="row pb-3">
                    <div className="col-12">
                      <p className="para_main" style={{ color: "white" }}>
                        {error}
                      </p>
                    </div>
                  </div>
                )
              )}
              <input
                required
                type="name"
                value={name}
                maxLength="24"
                placeholder="Full Name"
                onChange={(e) => setName(e.target.value)}
              />
              <input
                required
                type="email"
                value={email}
                maxLength="36"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                required
                type="phone"
                value={phoneNum}
                maxLength="22"
                placeholder="Phone Number"
                onChange={(e) => setPhoneNum(e.target.value)}
              />
              <button type="submit" style={{ border: "none" }}>
                Submit
              </button>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FreeBook;
