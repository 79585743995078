import React, { useEffect, useState } from "react";
import styles from "./NextCaseStudies.module.css";
import { Container, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { listCaseStudy } from "../../../store/actions/caseStudyActions";
import { Link, useParams } from "react-router-dom";

const NextCaseStudies = () => {
  const params = useParams();
  const id = params.id;
  const dispatch = useDispatch();

  const caseStudyList = useSelector((state) => state.caseStudyList);
  const { loading, error, caseStudies } = caseStudyList;
  const filtered =
    caseStudies && caseStudies.filter((item, index) => item._id !== id);
  const item = filtered[Math.floor(Math.random() * filtered.length)];

  useEffect(() => {
    dispatch(listCaseStudy());
  }, []);

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );
  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  return (
    <div
      style={{
        backgroundImage: matches
          ? "url(/images/next_casestudy_background.jpg)"
          : "url(/images/next_casestudy_mob_background.jpg)",
      }}
      className={styles.main}
    >
      <Container>
        {loading ? (
          <span
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner
              style={{
                color: "red",
                width: "120px",
                height: "120px",
                alignSelf: "center",
                textAlign: "center",
              }}
            />
          </span>
        ) : error ? (
          <p className="para_main" style={{ color: "red" }}>
            {error}
          </p>
        ) : (
          caseStudies.length !== 0 &&
          !loading &&
          !error && (
            <div className={styles.contentCol}>
              <h2 className="h2_main">Next Case Study</h2>
              <h1 className="h1_main">
                <span>{item.clientName}</span>
              </h1>
              <p className="para_main">
                Hear the success stories about some of our clients. A big thank
                you to those who choose us. You never know, your success story
                could be next.
              </p>
              <Link
                style={{ textDecoration: "none" }}
                to={`/casestudy/${item._id}`}
              >
                <img src="/images/icons/chevron-down.svg" />
              </Link>
            </div>
          )
        )}
      </Container>
    </div>
  );
};

export default NextCaseStudies;
