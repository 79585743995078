import React, { useState } from "react";
import styles from "./FAQs.module.css";
import "./AnimationStyles.css";
import { Col, Container, Row } from "react-bootstrap";

const FAQs = ({ Data, text, gradientReverse }) => {
  const [List, setList] = useState({ faqs: Data });

  const showAnsHandler = (id) => {
    const nextArr = List.faqs.map((item) => {
      if (item.id === id) {
        return { ...item, showAns: !item.showAns };
      }
      return item;
    });
    setList({ faqs: nextArr });
  };
  return (
    <div
      style={{
        background:
          gradientReverse &&
          `linear-gradient(to top, #121315, #121315, #353638)`,
      }}
      className={styles.main}
    >
      <Container>
        <Row className="gy-4">
          <Col lg={12}>
            <div className={styles.contentCont}>
              <h1 className="h1_main">Frequently Asked Questions</h1>
              <h2 className="h2_main">
                {text ? text : "Still have questions?"}
              </h2>
              <img src="/images/faqs_people.png" />
            </div>
          </Col>
          <Col lg={12}>
            <div className={styles.list}>
              {List.faqs.map((item) => (
                <div className={styles.listItem}>
                  <div
                    onClick={() => showAnsHandler(item.id)}
                    className={styles.head}
                  >
                    <h2
                      style={{ color: item.showAns && "#EC1D23" }}
                      className="h3_main"
                    >
                      {item.question}
                    </h2>
                    <img
                      alt=""
                      src={
                        !item.showAns
                          ? "/images/icondown.svg"
                          : "/images/iconup.svg"
                      }
                    />
                  </div>
                  <p
                    className={`para_main faq_slide ${
                      item.showAns ? "faq_slide-in" : "faq_slide-out"
                    }`}
                  >
                    {item.answer}
                  </p>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FAQs;
