import React from "react";
import styles from "./ImgByContent.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { PopupButton } from "react-calendly";
import { Link } from "react-router-dom";

const ImgByContent = ({
  img,
  span,
  heading,
  text,
  buttonText,
  buttonLink,
  bookConsultation,
  tagline,
  reverse,
  gradient,
  gradientReverse,
  gradientSpecific,
  background,
}) => {
  return (
    <div
      style={{
        background: gradient
          ? "linear-gradient(to bottom, #080707, #080707, #050505)"
          : gradientSpecific
          ? gradientSpecific
          : gradientReverse
          ? "linear-gradient(to bottom, #050505, #050505, #333436)"
          : background
          ? background
          : "#050505",
      }}
      className={styles.main}
    >
      <Container>
        <Row
          style={{ flexDirection: reverse && "row-reverse" }}
          className="gy-4 gx-lg-5"
        >
          <Col lg={6}>
            <div className={styles.imgCol}>
              <img className="img-fluid" src={img} />
            </div>
          </Col>
          <Col lg={6}>
            <div className={styles.contentCol}>
              {tagline && <h2 className="h2_main">{tagline}</h2>}
              <h1 className="h1_main">
                <span>{span}</span> {heading}
              </h1>
              {text && <p className="para_main">{text}</p>}
              {bookConsultation && (
                <p className="para_main">
                  Step confidently onto Canadian soil with our bespoke in-person
                  and online advisory services. We prioritize a seamless and
                  cost-efficient immigration experience for you, integrating the
                  latest technological tools. Experience a uniquely tailored
                  immigration plan, meticulously crafted to fit your
                  aspirations. <br />
                  <br /> Under the seasoned guidance of Principal Consultant
                  RCIC Koneswaran Thurairasah, Lodestone Immigration stands as a
                  beacon of trust and quality, serving clients across the globe.
                  From Temporary Residency to Permanent Settlement, we"ve got
                  you covered. <br />
                  <br />
                  Proudly Canadian and fully compliant with the standards of the
                  College of Immigration and Citizenship Consultants (CICC), our
                  ethos is simple: every client is unique, and every journey
                  deserves our utmost dedication. Let us partner with you on
                  your distinctive route to Permanent Residence. <br /> <br />{" "}
                  <span
                    style={{
                      backgroundColor: "red",
                      padding: "4px 8px 4px 8px",
                      width:"10px",
                      height:"10px",
                      borderRadius: "100px",
                      marginRight: "10px",
                    }}
                  >
                    ✔
                  </span>
                  Governed by Canadian Legal Authorities <br />
                  <br />
                  <span
                    style={{
                      backgroundColor: "red",
                      padding: "4px 8px 4px 8px",
                      width:"10px",
                      height:"10px",
                      borderRadius: "100px",
                      marginRight: "10px",
                    }}
                  >
                    ✔
                  </span>
                  Official Representation at IRCC for Canadian Immigration
                  Matters <br />
                  <br />
                  <span
                    style={{
                      backgroundColor: "red",
                      padding: "4px 8px 4px 8px",
                      width:"10px",
                      height:"10px",
                      borderRadius: "100px",
                      marginRight: "10px",
                    }}
                  >
                    ✔
                  </span>
                  Transparent and Detailed Consultations, Ethical, and Impactful
                  Guidance
                </p>
              )}
              {buttonText && (
                <>
                  {!bookConsultation && (
                    <Link to={buttonLink}>
                      {" "}
                      <button className="button_main">{buttonText}</button>
                    </Link>
                  )}
                  {bookConsultation && (
                    <a
                      target="no_blank"
                      href="https://calendly.com/lodestoneimmigration"
                    >
                      <button className="button_main">{buttonText}</button>
                    </a>
                    // <PopupButton
                    //   url="https://calendly.com/lodestoneimmigration"
                    //   className="button_main"
                    //   rootElement={document.getElementById("root")}
                    //   text={buttonText}
                    // ></PopupButton>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ImgByContent;
