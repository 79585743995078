import React from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import styles from "./ImgContent.module.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ImgContent = ({
  heading,
  para,
  para2,
  img,
  heading2,
  para3,
  heading3,
  para4,
  heading4,
  para5,
  heading5,
  para6,
  heading6,
  para7,
  heading7,
  para8,
  heading8,
  para9,
  heading9,
  para10,
  paddingBottom,
  paddingTop,
}) => {
  const caseStudyList = useSelector((state) => state.caseStudyList);
  const { loading, error, caseStudies } = caseStudyList;

  const filtered = caseStudies.filter((item, index) => index <= 3);

  const navigate = useNavigate();

  const redirectUser = (id) => {
    navigate(`/casestudy/${id}`);
  };
  return (
    <div
      style={{ paddingBottom: paddingBottom, paddingTop: paddingTop }}
      className={styles.main}
    >
      <Container>
        {loading ? (
          <span
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner
              style={{
                color: "red",
                width: "120px",
                height: "120px",
                alignSelf: "center",
                textAlign: "center",
              }}
            />
          </span>
        ) : error ? (
          <p className="para_main" style={{ color: "red" }}>
            {error}
          </p>
        ) : (
          <Row className="gy-5 gx-lg-5">
            <Col lg={7}>
              <Row className="gy-5">
                <Col lg={12}>
                  <div className={styles.imgCont}>
                    <img className="img-fluid" src={img} />
                  </div>
                </Col>
                <Col lg={12}>
                  <div className={styles.content}>
                    <h1 className="h2_main pb-4">{heading}</h1>
                    <div
                      dangerouslySetInnerHTML={{ __html: para }}
                      style={{ color: "white" }}
                      className="para_main text-start"
                    />
                    <br />
                    <div
                      dangerouslySetInnerHTML={{ __html: para2 }}
                      style={{ color: "white" }}
                      className="para_main text-start pb-5"
                    />

                    {(heading2 || para3 )&& (
                      <>
                         {heading2 &&<h1 className="h2_main pb-4">{heading2}</h1>}
                        <div
                          dangerouslySetInnerHTML={{ __html: para3 }}
                          style={{ color: "white" }}
                          className="para_main pb-5"
                        />
                      </>
                    )}
                    {(heading3 || para4 )&& (
                      <>
                         {heading3 &&<h1 className="h2_main pb-4">{heading3}</h1>}
                        <div
                          dangerouslySetInnerHTML={{ __html: para4 }}
                          style={{ color: "white" }}
                          className="para_main pb-5"
                        />
                      </>
                    )}
                    {(heading4 || para5 )&& (
                      <>
                         {heading4 &&<h1 className="h2_main pb-4">{heading4}</h1>}
                        <div
                          dangerouslySetInnerHTML={{ __html: para5 }}
                          style={{ color: "white" }}
                          className="para_main pb-5"
                        />
                      </>
                    )}
                    {(heading5 || para6 )&& (
                      <>
                         {heading5 &&<h1 className="h2_main pb-4">{heading5}</h1>}
                        <div
                          dangerouslySetInnerHTML={{ __html: para6 }}
                          style={{ color: "white" }}
                          className="para_main pb-5"
                        />
                      </>
                    )}
                    {(heading6 || para7 )&& (
                      <>
                         {heading6 &&<h1 className="h2_main pb-4">{heading6}</h1>}
                        <div
                          dangerouslySetInnerHTML={{ __html: para7 }}
                          style={{ color: "white" }}
                          className="para_main pb-5"
                        />
                      </>
                    )}
                    {(heading7 || para8 )&& (
                      <>
                         {heading7 &&<h1 className="h2_main pb-4">{heading7}</h1>}
                        <div
                          dangerouslySetInnerHTML={{ __html: para8 }}
                          style={{ color: "white" }}
                          className="para_main pb-5"
                        />
                      </>
                    )}
                    {(heading8 || para9 )&& (
                      <>
                         {heading8 &&<h1 className="h2_main pb-4">{heading8}</h1>}
                        <div
                          dangerouslySetInnerHTML={{ __html: para9 }}
                          style={{ color: "white" }}
                          className="para_main pb-5"
                        />
                      </>
                    )}
                    {(heading9 || para10) && (
                      <>
                         {heading9 &&<h1 className="h2_main pb-4">{heading9}</h1>}
                        <div
                          dangerouslySetInnerHTML={{ __html: para10 }}
                          style={{ color: "white" }}
                          className="para_main pb-5"
                        />
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={5}>
              <div className={styles.contentCol}>
                <h1 className="h1_main pb-3">Most Popular</h1>
                {filtered.map((item) => {
                  return (
                    <div
                      onClick={() => redirectUser(item._id)}
                      className={styles.contentBox}
                    >
                      <img src={item.bannerImage} />
                      <div>
                        <h4 className="h4_main pb-2">{item.clientName}</h4>
                        <p style={{ width: "auto" }} className="para_main pe-2">
                          {item.dateCreated}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default ImgContent;
