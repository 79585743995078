import React, { useEffect, useState } from "react";
import styles from "./Vision.module.css";
import { Col, Container, Row } from "react-bootstrap";

const Vision = () => {
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 992px)").matches
  );
  useEffect(() => {
    window
      .matchMedia("(min-width: 992px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  return (
    <div className={styles.main}>
      <Container>
        <Row className="gy-4 d-flex justify-content-center align-items-center">
          <Col
            className="d-flex justify-content-center align-items-center"
            style={{
              padding: matches && "0px",
            }}
            xl={4}
            lg={6}
          >
            <div
              style={{
                backgroundImage:
                  matches && "url('/images/vision_background.png')",
              }}
              className={styles.box}
            >
              <img src="/images/icons/vision.svg" />
              <h2 className="h2_main pb-4">Vision</h2>
              <p className="para_main">
                Fostering a world where diversity thrives and every immigration
                dream is realized.
              </p>
            </div>
          </Col>
          <Col
            className="d-flex justify-content-center align-items-center"
            style={{
              padding: matches && "0px",
            }}
            xl={4}
            lg={6}
          >
            <div
              style={{
                backgroundImage:
                  matches && "url('/images/mission_background.png')",
              }}
              className={styles.box}
            >
              <img src="/images/icons/mission.svg" />
              <h2 className="h2_main pb-4">Mission</h2>
              <p className="para_main">
                Connecting lives and cultures by transforming immigration
                aspirations into reality through dedicated expertise.
              </p>
            </div>
          </Col>
          <Col
            className="d-flex justify-content-center align-items-center"
            style={{
              padding: matches && "0px",
            }}
            xl={4}
            lg={6}
          >
            <div
              style={{
                backgroundImage:
                  matches && "url('/images/values_background.png')",
              }}
              className={styles.box}
            >
              <img src="/images/icons/values.svg" />
              <h2 className="h2_main pb-4">Values</h2>
              <p className="para_main">
                Championing diversity, integrity, and innovation to open doors
                of opportunity and fairness for all.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Vision;
