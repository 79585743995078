import React from "react";
import ImgByContent from "../../components/ImgByContent/ImgByContent";
import Hero from "../../components/Hero/Hero";
import Footer from "../../components/Footer/Footer";
import Awards from "../../components/Awards/Awards";
import { Data } from "./Data";
import Testimonial from "../../components/Testimonials/Testimonials";
import Blogs from "../../components/Blogs/Blogs";
import IdvServicesTabs from "../../components/IdvServicesTabs/IdvServicesTabs";
import FAQs from "../../components/FAQs/FAQs";
import { TabData } from "./TabData";

const FairnesLetter = () => {
  return (
    <>
      <Hero
        span="PROCEDURAL"
        heading={"FAIRNES LETTER"}
        img={"/images/procedural-hero.webp"}
        mobileImg={"/images/procedural-mob-hero.webp"}
      />
      <ImgByContent
        gradientSpecific={
          "linear-gradient(to bottom, #080707, #080707, #121315)"
        }
        reverse={true}
        span={"PROCEDURAL "}
        heading={"FAIRNES LETTER"}
        img={"/images/procedural2.webp"}
        text={`A Procedural Fairness Letter (PFL) is a critical document within the Canadian immigration process. Issued by Immigration, Refugees, and Citizenship Canada (IRCC), this letter is a signal that there may be concerns about an applicant's pending immigration case. Rather than an outright denial, the PFL provides applicants an opportunity to address and rectify potential issues in their application. Essentially, it upholds the principle of fairness, ensuring that applicants are made aware of concerns and are given a chance to respond before a final decision is made. It's a testament to Canada's commitment to transparent and just immigration proceedings.

        Note: The PFL represents a crucial chance to submit substantial evidence backing your application. Approach this opportunity with utmost seriousness and care. A comprehensive understanding and meticulous response are imperative.
        `}
      />
      <IdvServicesTabs Data={TabData} />
      <Awards />
      <FAQs text={"Procedural Fairness Letter"} Data={Data} />
      <Testimonial />
      <Blogs />
      <Footer />
    </>
  );
};

export default FairnesLetter;
