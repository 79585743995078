import React from "react";
import Hero from "../../components/Hero/Hero";
import BlogSec from "../../components/BlogSec/BlogSec";
import Footer from "../../components/Footer/Footer";
import Contactform from "../../components/Contact Form/Contactform";

const Blog = () => {
  return (
    <>
      <Hero
        heading="BLOGS"
        img={"/images/blog-hero.webp"}
        mobileImg={"/images/blog-hero-mob.webp"}
        text={"Insight and Updates: Navigating the Landscape of Canadian Immigration"}
      />
      <BlogSec />
      <Contactform />
      <Footer />
    </>
  );
};

export default Blog;
