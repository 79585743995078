import React from "react";
import ImgByContent from "../../components/ImgByContent/ImgByContent";
import Hero from "../../components/Hero/Hero";
import Footer from "../../components/Footer/Footer";
import Awards from "../../components/Awards/Awards";
import { Data } from "./Data";
import Testimonial from "../../components/Testimonials/Testimonials";
import Blogs from "../../components/Blogs/Blogs";
import IdvServicesTabs from "../../components/IdvServicesTabs/IdvServicesTabs";
import FAQs from "../../components/FAQs/FAQs";
import { TabData } from "./TabData";

const RejectedApplications = () => {
  return (
    <>
      <Hero
        span="REJECTED"
        heading={"APPLICATION REVIEW SERVICES"}
        img={"/images/rejected-hero.webp"}
        mobileImg={"/images/rejected-mob-hero.webp"}
      />
      <ImgByContent
        gradientSpecific={
          "linear-gradient(to bottom, #080707, #080707, #121315)"
        }
        reverse={true}
        span={"REFUSED/ REJECTED"}
        heading={"APPLICATION REVIEW SERVICES"}
        img={"/images/review-rejected-application2.webp"}
        text={`If you've submitted an application for a Study Permit, Work Permit, or Visitor Visa and feel that IRCC may have erroneously denied your request, or if you're seeking clarity on the reasons for the refusal, we're here to help.         `}
      />
      <IdvServicesTabs Data={TabData} />
      <Awards />
      <FAQs text={"Review Rejected Applications"} Data={Data} />
      <Testimonial />
      <Blogs />
      <Footer />
    </>
  );
};

export default RejectedApplications;
