import React from "react";
import { Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import styles from "./WhyLodestone.module.css";
import "./WhyLodestone.css";
import "swiper/css";
import "swiper/css/pagination";

const WhyLodestone = () => {
  return (
    <div className={styles.main}>
      <Container>
        <h1 className="h1_main text-center">
          WHAT MAKES <span>LODESTONE</span> DIFFERENT?
        </h1>
        <div className={styles.swiperCont}>
          <Swiper
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            spaceBetween={25}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              280: {
                slidesPerView: 1,
              },
              992: {
                slidesPerView: 2,
              },
            }}
            modules={[Autoplay, Pagination]}
            className="why_swiper"
          >
            <SwiperSlide>
              <div className={styles.box}>
                <div className={styles.head}>
                  <img src="/images/icons/industry_recognized_expertise.png" />
                  <h2 className="h2_main">Industry Recognized Expertise</h2>
                </div>
                <div className={styles.contentCol}>
                  <p className="para_main">
                    Lodestone Immigration operates under the guidance of
                    professionals affiliated with respected regulatory
                    institutions, guaranteeing that clients receive top-tier,
                    compliant services.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.box}>
                <div className={styles.head}>
                  <img src="/images/icons/customized_guidance.png" />
                  <h2 className="h2_main">Customized Guidance</h2>
                </div>
                <div className={styles.contentCol}>
                  <p className="para_main">
                    Understanding that every immigration story is distinct,
                    Lodestone crafts individualized plans, ensuring each client
                    receives the most relevant and effective advice for their
                    unique situation.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.box}>
                <div className={styles.head}>
                  <img src="/images/icons/art.png" />
                  <h2 className="h2_main">State-of-the-Art Processes</h2>
                </div>
                <div className={styles.contentCol}>
                  <p className="para_main">
                    Lodestone embraces cutting-edge technologies to streamline
                    documentation and application procedures, offering clients a
                    hassle-free experience with minimized delays.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.box}>
                <div className={styles.head}>
                  <img src="/images/icons/sustainability.png" />
                  <h2 className="h2_main">Commitment to Sustainability</h2>
                </div>
                <div className={styles.contentCol}>
                  <p className="para_main">
                    By adopting green initiatives and digital solutions,
                    Lodestone showcases its dedication not only to its clients
                    but also to the planet, emphasizing responsible business
                    practices.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.box}>
                <div className={styles.head}>
                  <img src="/images/icons/honesty_and_clarity.png" />
                  <h2 className="h2_main">Honesty and Clarity</h2>
                </div>
                <div className={styles.contentCol}>
                  <p className="para_main">
                    At Lodestone, transparency isn't just a buzzword; it's a
                    commitment. Clients are kept in the loop with clear fee
                    structures and step-by-step updates, ensuring a trust-filled
                    partnership throughout the immigration journey.
                  </p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </Container>
    </div>
  );
};

export default WhyLodestone;
