import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import styles from "./GetConsultation.module.css";

const GetConsultation = () => {
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );
  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);
  return (
    <div
      style={{
        backgroundImage: matches
          ? `url('/images/get_consultation_background.webp')`
          : `url('/images/get_consultation_background_mob.webp')`,
      }}
      className={styles.main}
    >
      <Container>
        <div className={styles.box}>
          <h1 className="h1_main">
            Family. Work. Settle. <br/>In Your Dream Country <span>Canada</span>
          </h1>
          <p className="para_main">
            Embrace a new beginning in Canada, where you can nurture your
            family, pursue your career, and establish lasting roots in a nation
            that welcomes dreams and diversity.
          </p>
          <a target="no_blank" href="https://calendly.com/lodestoneimmigration">
            <button className="button_main">Book Consultation</button>
          </a>
        </div>
      </Container>
    </div>
  );
};

export default GetConsultation;
