export const TabData = [
  {
    id: "1",
    title: "Common Causes for Application Rejection",
    text: `Understanding Application Denials: Applications for Canadian immigration can be denied for a number of reasons, with inaccuracies or missing information in documentation being common culprits. Challenges may also arise if there's doubt about an applicant's eligibility or their commitment to comply with the conditions of the desired immigration program. It's crucial to recognize and rectify these issues to prevent them from impacting future applications.`,
  },
  {
    id: "2",
    title: "Expert Analysis of Your Refusal",
    text: `Assessment by Our RCIC: At Lodestone Immigration, our Regulated Canadian Immigration Consultant offers a detailed analysis of your refusal. We review your refusal letter and all related documentation to understand the reasons behind the decision. Our goal is to provide you with a clear picture of your application's strengths and weaknesses, enhancing your prospects for a successful reapplication.`,
  },
  {
    id: "3",
    title: "Detailed Review and Guidance",
    text: `Written Review of Findings: You will benefit from a thorough written review that breaks down our findings and offers strategic advice. This service is especially beneficial for applicants seeking a deeper comprehension of the refusal reasons, equipping them with the knowledge needed for a stronger reapplication. It's important to provide us with all relevant documents, including any available GCMS notes, for a comprehensive assessment.`,
  },
  {
    id: "4",
    title: "Preparing for Reapplication",
    text: `Preparing for a Strong Reapplication: Armed with insights from Lodestone Immigration's review, you can approach your reapplication or next steps with confidence. Our expert guidance is designed to help you understand the intricacies of your refusal and to prepare you for the path ahead. By addressing the reasons for rejection and providing all necessary documentation, you're well on your way to successfully navigating the Canadian immigration process.`,
  },
];
