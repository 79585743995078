export const Data = [
  {
    id: "1",
    question: "What is the difference between a Visitor Visa and a Super Visa?",
    answer: `A Visitor Visa allows foreign nationals to stay in Canada for up to 6 months, while a Super Visa permits parents and grandparents of Canadian citizens or permanent residents to stay for up to 2 years per visit without renewing their status.    `,
    showAns: false,
  },
  {
    id: "2",
    question: "Who is eligible for a Super Visa?",
    answer: `The Super Visa is designed for parents and grandparents of Canadian citizens or permanent residents. Applicants must also meet certain requirements, including a letter of invitation from their family member in Canada and proof of medical insurance.    `,
    showAns: false,
  },
  {
    id: "3",
    question: "What is a Business Visa?",
    answer: `A Business Visa is meant for individuals intending to travel to Canada for business purposes, such as attending conferences, meetings, or exploring business opportunities.    `,
    showAns: false,
  },
  {
    id: "4",
    question: "How long can I stay in Canada on a Business Visa?",
    answer: `Typically, a Business Visa allows you to stay in Canada for up to 6 months. However, the exact duration will be determined by the immigration officer upon your arrival.    `,
    showAns: false,
  },
  {
    id: "5",
    question: "Can I extend my stay on a Visitor or Business Visa?",
    answer: `Yes, you can apply for an extension, but it's advisable to do so at least 30 days before your current visa expires.    `,
    showAns: false,
  },
  {
    id: "6",
    question: "Do I need an invitation letter for a Visitor or Business Visa?",
    answer: `While it's mandatory for a Visitor Visa, and for Business Visas, an invitation from a Canadian company can be beneficial.`,
    showAns: false,
  },
  {
    id: "7",
    question: "What are the insurance requirements for a Super Visa?",
    answer: `Applicants must have at least one year of Canadian medical insurance coverage with a minimum coverage of CAD 100,000. `,
    showAns: false,
  },
  {
    id: "8",
    question: "How long does it take to process these visas?",
    answer: `Processing times can vary based on the visa type, your country of residence, and current application volumes. Check with Lodestone Immigration for the most current timelines.`,
    showAns: false,
  },
  {
    id: "9",
    question: "Can I work in Canada with a Visitor or Business Visa?",
    answer: `No, these visas do not permit employment in Canada. If you intend to work, you'd need to apply for a Work Permit.`,
    showAns: false,
  },
  {
    id: "10",
    question: "What should I do if my visa application is refused?",
    answer: `If your application is refused, it's essential to understand the reasons for refusal. Lodestone Immigration can assist in reviewing your case and advising on the best course of action.    `,
    showAns: false,
  },
];
