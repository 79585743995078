import React from "react";
import ImgByContent from "../../components/ImgByContent/ImgByContent";
import Hero from "../../components/Hero/Hero";
import Footer from "../../components/Footer/Footer";
import Awards from "../../components/Awards/Awards";
import { Data } from "./Data";
import Testimonial from "../../components/Testimonials/Testimonials";
import Blogs from "../../components/Blogs/Blogs";
import IdvServicesTabs from "../../components/IdvServicesTabs/IdvServicesTabs";
import FAQs from "../../components/FAQs/FAQs";
import { TabData } from "./TabData";

const CanadianCitizenship = () => {
  return (
    <>
      <Hero
        span="CANADIAN"
        heading={"CITIZENSHIP"}
        img={"/images/citizenship-hero.webp"}
        mobileImg={"/images/citizenship-mob-hero.webp"}
      />
      <ImgByContent
        gradientSpecific={
          "linear-gradient(to bottom, #080707, #080707, #121315)"
        }
        reverse={true}
        span={"CANADIAN "}
        heading={"CITIZENSHIP"}
        img={"/images/citizenship2.webp"}
        text={`Numerous Canadian permanent residents, having navigated the intricacies of the immigration journey, eagerly anticipate the moment they can proudly call themselves official Canadian citizens. However, before that milestone is reached, there are several crucial steps that must be completed, ensuring the Immigration, Refugees, Citizenship Canada (IRCC) greenlights their Canadian citizenship application.        `}
      />
      <IdvServicesTabs Data={TabData} />
      <Awards />
      <FAQs text={"Canadian Citizenship"} Data={Data} />
      <Testimonial />
      <Blogs />
      <Footer />
    </>
  );
};

export default CanadianCitizenship;
