export const Data = [
  {
    id: "1",
    question:
      "What are the Consultation & Advisory Services offered by Lodestone Immigration?",
    answer: `Our Consultation & Advisory Services provide expert guidance and advice on various aspects of Canadian immigration. This includes understanding the immigration process, eligibility criteria, documentation requirements, and more, tailored to your unique situation.    `,
    showAns: false,
  },
  {
    id: "2",
    question: "How can I schedule a consultation with Lodestone Immigration?",
    answer: `You can book an appointment through our website's "Book an appointment button" section.`,
    showAns: false,
  },
  {
    id: "3",
    question: "Do I need to be in Canada to avail of your advisory services?",
    answer: `No, our services are available to clients worldwide. We leverage digital communication tools to ensure you receive comprehensive advice regardless of your location.`,
    showAns: false,
  },
  {
    id: "4",
    question: "Why should I seek a consultation for Canadian immigration?",
    answer: `Canadian immigration processes can be intricate. A consultation provides clarity, ensures that you are on the right track, and increases your chances of success by helping you understand potential pitfalls and how to navigate them.    `,
    showAns: false,
  },
  {
    id: "5",
    question: "Are the consultation fees refundable?",
    answer: `Typically, consultation fees are non-refundable. However, any specific terms or exceptions will be discussed during your initial contact.    `,
    showAns: false,
  },
  {
    id: "6",
    question:
      "Can I switch from consultation services to full representation later on?",
    answer: `Absolutely! If you decide to proceed with a full application or need representation, we can transition and guide you through the entire immigration process.    `,
    showAns: false,
  },
  {
    id: "7",
    question: "How long does a typical consultation last?",
    answer: `Our standard consultation usually lasts between 30 minutes to an hour, but it can vary based on the complexities of the case and the client's needs.    `,
    showAns: false,
  },
  {
    id: "8",
    question: "Will I get a summary or action plan post-consultation?",
    answer: `Yes, after our consultation, we provide a summary of our discussion and a recommended action plan tailored to your specific situation.`,
    showAns: false,
  },
  {
    id: "9",
    question:
      "Do you provide consultations for all types of immigration pathways?",
    answer: `Yes, our experts are well-versed in a range of Canadian immigration pathways, from family sponsorship to work permits, and can provide guidance tailored to your desired path.    `,
    showAns: false,
  },
  {
    id: "10",
    question:
      "Is my information confidential during and after the consultation?",
    answer: `Absolutely! Client confidentiality is of utmost importance to us. All information shared during consultations is kept private and secure.    `,
    showAns: false,
  },
];
