export const Data = [
  {
    id: "1",
    question: "What types of work permits are available in Canada?",
    answer: `Canada offers several types of work permits, including Open Work Permits, Employer-Specific Work Permits, and the International Mobility Program. Lodestone Immigration can help determine which permit suits your situation.    `,
    showAns: false,
  },
  {
    id: "2",
    question: "Do I need a job offer to obtain a work permit in Canada?    ",
    answer: `For an Employer-Specific Work Permit, yes, you need a job offer from a Canadian employer. However, an Open Work Permit does not require a job offer.    `,
    showAns: false,
  },
  {
    id: "3",
    question: "What is an LMIA, and do I need one?",
    answer: `A Labour Market Impact Assessment (LMIA) is a document that a Canadian employer may need to obtain before hiring a foreign worker. It shows there's a need for a foreign worker and that no Canadian worker is available for the job. Whether you need an LMIA depends on the type of work permit you are applying for.    `,
    showAns: false,
  },
  {
    id: "4",
    question: "How can I find a job in Canada?",
    answer: `Job hunting in Canada can be done through various online job portals, recruitment agencies, and networking. Lodestone Immigration can provide guidance on job search strategies suitable for international applicants.    `,
    showAns: false,
  },
  {
    id: "5",
    question:
      "Can I apply for Canadian Permanent Residence while on a work permit?",
    answer: `Yes, having Canadian work experience may make you eligible for certain permanent residency programs like the Canadian Experience Class within Express Entry.    `,
    showAns: false,
  },
  {
    id: "6",
    question: "How long can I work in Canada on a work permit?",
    answer: `The duration of your work permit depends on the type of permit, your job offer, and the decision made by the immigration officer. Lodestone Immigration can advise on your specific case.`,
    showAns: false,
  },
  {
    id: "7",
    question: "Is it possible to extend my work permit?",
    answer: `Yes, in many cases you can apply to extend your work permit if you meet the requirements. Lodestone Immigration can assist with the extension process.    `,
    showAns: false,
  },
  {
    id: "8",
    question: "Can my family come with me to Canada if I have a work permit? ",
    answer: `Yes, your spouse or common-law partner might be eligible for an open work permit, and dependent children can study in Canada.    `,
    showAns: false,
  },
  {
    id: "9",
    question: "What happens if my work permit application is refused?",
    answer: `Lodestone Immigration can help you understand the reasons behind the refusal and advise on the best course of action, which might include addressing the refusal reasons in a new application.`,
    showAns: false,
  },
  {
    id: "10",
    question: "How long does it take to process a work permit application?",
    answer: `Processing times for work permits vary by country and the specifics of the job offer. Lodestone Immigration can provide an estimate based on your particular situation.    `,
    showAns: false,
  },
  {
    id: "11",
    question: "How can Lodestone Immigration assist me in working in Canada?",
    answer: `Lodestone Immigration offers comprehensive services, from helping you understand the types of work permits to guiding you through the application process, ensuring all the proper procedures and documentation are handled efficiently.    `,
    showAns: false,
  },
];
