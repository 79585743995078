import React, { useEffect } from "react";
import styles from "./CaseStudies.module.css";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { listCaseStudy } from "../../store/actions/caseStudyActions";
import { useDispatch, useSelector } from "react-redux";

const CaseStudies = () => {
  const dispatch = useDispatch();
  const caseStudyList = useSelector((state) => state.caseStudyList);
  const { loading, error, caseStudies } = caseStudyList;

  const filtered =
    caseStudies && caseStudies.sort(() => Math.random()).slice(0, 2);

  useEffect(() => {
    dispatch(listCaseStudy());
  }, []);


  return (
    <div className={styles.main}>
      <Container>
        <div className={styles.head}>
          <div style={{ width: "50%" }}>
            <h2 className="h2_main pb-3">IMMIGRATION CASE STUDIES</h2>
            <h1 className="h1_main">
              Explore real-life scenarios of immigration journeys
            </h1>
          </div>
          <Link to="/portfolio">
            <button className="button_main">View All</button>
          </Link>
        </div>
        {loading ? (
          <span
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner
              style={{
                color: "red",
                width: "120px",
                height: "120px",
                alignSelf: "center",
                textAlign: "center",
              }}
            />
          </span>
        ) : error ? (
          <span
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="para_main" style={{ color: "red" }}>
              {error}
            </p>
          </span>
        ) : (
          <Row className="gy-4 pt-5">
            {caseStudies.length !== 0 &&
              filtered.map((item) => (
                <Col lg={6}>
                  <div className={styles.box}>
                    <Link
                      style={{ textDecoration: "none" }}
                      to={`/casestudy/${item._id}`}
                    >
                      <img src={item.bannerImage} />
                    </Link>
                    <h4 className="h4_main">{item.clientName}</h4>
                    <div className={styles.bottomRow}>
                      <div className={styles.readMore}>
                        <Link
                          style={{ textDecoration: "none" }}
                          to={`/casestudy/${item._id}`}
                        >
                          <h5 className="h5_main">Read more</h5>
                        </Link>
                        <img src="/images/arrowright.svg" />
                      </div>
                      <p className="para_main">{item.dateCreated.slice(0, 10)}</p>
                    </div>
                  </div>
                </Col>
              ))}
          </Row>
        )}
      </Container>
    </div>
  );
};

export default CaseStudies;
