import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { Links1, Links2, Links3 } from "./Links";
import Mobilenav from "./Mobilenav";
import "./NavbarCS.css";
import "./mobileNav.css";
import styles from "./Header.module.css";
import { PopupButton, useCalendlyEventListener } from "react-calendly";

const Header = ({ scrollToFreeBook, background, noLinearBackground }) => {
  const navigate = useNavigate();
  const [selected5, setSelected5] = useState(false);
  const [style, setStyle] = useState({ display: "none" });
  const [showShadow, setShowShadow] = useState(false);

  const hamburgerdropdown = (state) => {
    setSelected5(!state);
  };

  const mouseEnterHandler = () => {
    setStyle({ display: "block" });
  };
  const mouseLeaveHandler = () => {
    setStyle({ display: "none" });
  };

  window.onscroll = function () {
    if (window.scrollY > 22) {
      setShowShadow(true);
    } else {
      setShowShadow(false);
    }
  };

  const handleFreeEbookClick = () => {
    navigate("/home", { state: { scrollToFreeBook: true } });
  };

  return (
    <>
      <>
        <header
          style={{ background: background && background }}
          className={`${styles.desktopHeader} ${showShadow && styles.sha_dow}`}
        >
          <Container>
            <div className={styles.main}>
              <div className={styles.logo}>
                <Link onMouseEnter={() => mouseLeaveHandler()} to="/">
                  <img alt="Lodestone" src="/images/logo.png" />
                </Link>
                <div className={styles.tabs}>
                  <Link
                    onMouseEnter={() => mouseLeaveHandler()}
                    className={styles.link}
                    to="/"
                  >
                    <h5 className="h5_main">Home</h5>
                  </Link>
                  <Link
                    onMouseEnter={() => mouseLeaveHandler()}
                    className={styles.link}
                    to="/about"
                  >
                    <h5 className="h5_main">About</h5>
                  </Link>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={handleFreeEbookClick}
                    onMouseEnter={() => mouseLeaveHandler()}
                    className={styles.link}
                  >
                    <h5 className="h5_main">Free E-book</h5>
                  </a>
                  <Link
                    onMouseEnter={() => mouseEnterHandler()}
                    onClick={mouseLeaveHandler}
                    className={styles.link}
                    to="/services"
                  >
                    <h5 className="h5_main">Services</h5>
                  </Link>
                  <Link
                    onMouseEnter={() => mouseLeaveHandler()}
                    className={styles.link}
                    to="/portfolio"
                  >
                    <h5 className="h5_main">Case Studies</h5>
                  </Link>
                  <Link
                    onMouseEnter={() => mouseLeaveHandler()}
                    className={styles.link}
                    to="/blog"
                  >
                    <h5 className="h5_main">Articles</h5>
                  </Link>
                  <Link
                    onMouseEnter={() => mouseLeaveHandler()}
                    className={styles.link}
                    to="/contact"
                  >
                    <h5 className="h5_main">Contact</h5>
                  </Link>
                </div>
              </div>
              <a
                onMouseEnter={() => mouseLeaveHandler()}
                target="no_blank"
                href="https://calendly.com/lodestoneimmigration"
              >
                <button className="button_main">Book Consultation</button>
              </a>
            </div>
          </Container>
        </header>

        <div
          onMouseLeave={mouseLeaveHandler}
          style={style}
          className={styles.drop_down}
        >
          <Container>
            <div
              onMouseLeave={mouseLeaveHandler}
              className={styles.drop_downContent}
              style={{
                zIndex: noLinearBackground ? "9999" : "1",
                top: noLinearBackground ? "90px" : "0",
              }}
            >
              <Row>
                <Col lg={8}>
                  <Row style={{ padding: "60px 16px 60px 60px" }}>
                    <Col lg={4}>
                      <div className={styles.listCol}>
                        <h3>Temporary Resident</h3>
                        {Links1.map((e) => (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? styles.listColLinkActive
                                : styles.listColLink
                            }
                            to={e.path}
                          >
                            {e.title}
                          </NavLink>
                        ))}
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className={styles.listCol}>
                        <h3>Permanent Resident</h3>
                        {Links2.map((e) => (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? styles.listColLinkActive
                                : styles.listColLink
                            }
                            to={e.path}
                          >
                            {e.title}
                          </NavLink>
                        ))}
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className={styles.listCol}>
                        <h3>Other Services</h3>
                        {Links3.map((e) => (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? styles.listColLinkActive
                                : styles.listColLink
                            }
                            to={e.path}
                          >
                            {e.title}
                          </NavLink>
                        ))}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg={4}>
                  <div className={styles.box}>
                    <div className={styles.head}>
                      <h4 className="h3_main">Personalized Consultation</h4>
                    </div>
                    <p className="para_main pb-3">
                      Book your consultation today to discuss your case with our
                      experienced RCIC
                    </p>
                    <a
                      target="no_blank"
                      href="https://calendly.com/lodestoneimmigration"
                    >
                      <button>Book Consultation</button>
                    </a>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>

      <div
        style={{ background: background && background }}
        className={`${styles.mobileHeader} ${showShadow && styles.sha_dow}`}
      >
        <header
          style={{
            width: "100%",
            zIndex: 99,
          }}
          className="header_cs_1"
        >
          <div className="container menu_cs_1 px-sm-3 px-4 d-flex justify-content-space-between align-content-center ">
            <h1 className="text-center" onClick={() => navigate("/home")}>
              <img
                style={{ maxHeight: "40px", maxWidth: "185px" }}
                src="/images/logo.png"
                className="img-fluid"
                alt=""
              />
            </h1>
            <div
              className="bars_cs1"
              onClick={() => hamburgerdropdown(selected5)}
            >
              <button
                style={{ padding: "0px" }}
                className={selected5 ? `fa solid fa-xmark` : `fa fa-bars`}
              ></button>
            </div>
          </div>
        </header>
        <Mobilenav
          // scrollToFreeBookMob={scrollToFreeBook}
          transfer={selected5}
        ></Mobilenav>
      </div>
    </>
  );
};

export default Header;
