import React, { useEffect, useState } from "react";
import { Data } from "./data";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import styles from "./CaseStudiesList.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { useDispatch, useSelector } from "react-redux";
import { listCaseStudy } from "../../store/actions/caseStudyActions";
import { Link } from "react-router-dom";

const CaseStudiesList = ({ paddingBottom, paddingTop }) => {
  const dispatch = useDispatch();

  const [readmore, setReadmore] = useState(false);

  const caseStudyList = useSelector((state) => state.caseStudyList);
  const { loading, error, caseStudies } = caseStudyList;

  const filtered = !readmore
    ? caseStudies && caseStudies.filter((item, index) => index < 5)
    : caseStudies && caseStudies;

  useEffect(() => {
    dispatch(listCaseStudy());
  }, []);

  return (
    <div
      style={{ paddingBottom: paddingBottom, paddingTop: paddingTop }}
      className={styles.main}
    >
      <Container>
        <h1 className="h1_main">
          <span>Discover the best</span> insights from our RCIC
        </h1>
        <p className="para_main pb-5">
          Case studies discussed here are purely on the knowledge of RCIC
          dealing with multiple clients in the past. Case studies are useful for
          describing, comparing, evaluating, and understanding different issues
          that can arise and how we can handle them
        </p>

        <Row className={`${styles.rowCont} gy-5 gx-lg-5`}>
          {loading ? (
            <span
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner
                style={{
                  color: "red",
                  width: "120px",
                  height: "120px",
                  alignSelf: "center",
                  textAlign: "center",
                }}
              />
            </span>
          ) : error ? (
            <p className="para_main" style={{ color: "red" }}>
              {error}
            </p>
          ) :
          caseStudies ? (
            filtered.map((item) => (
              <Col lg={6}>
                <div className={styles.box}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/casestudy/${item._id}`}
                  >
                    <img
                      src={item.bannerImage}
                      className={styles.imgBackground}
                    />
                  </Link>
                  <div className={styles.head}>
                    <h2 className="h3_main">{item.clientName}</h2>
                    <div className="ps-3">
                      {/* <h4 className="h4_main">View Case Study</h4> */}
                      <Link
                        style={{ textDecoration: "none" }}
                        to={`/casestudy/${item._id}`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                        </svg>
                      </Link>
                    </div>
                  </div>
                  {/* <div className={styles.tags}>
                    <h5 className="h5_main">
                      <span>Work:</span> {item.category1 && `${item.category1}`}{" "}
                      {item.category2 && `| ${item.category2}`}{" "}
                      {item.category3 && `| ${item.category3}`}{" "}
                      {item.category4 && `| ${item.category4}`}
                    </h5>
                  </div> */}
                </div>
              </Col>
            ))
          ) : (
            <h6
              style={{
                color: "red",
                textAlign: "center",
                paddingTop: "50px",
                textTransform: "none",
              }}
              className="heading_main"
            >
              Sorry, No Matching Items Found
            </h6>
          )}
        </Row>
        {!readmore && !loading && caseStudies && caseStudies.length > 5 && (
          <div onClick={() => setReadmore(true)} className={styles.readMore}>
            <h2 className="h2_main">Read More</h2>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
            </svg>
          </div>
        )}
      </Container>
    </div>
  );
};

export default CaseStudiesList;
