export const Data = [
  {
    id: "1",
    question: "Who can I sponsor under the Family Sponsorship program?",
    answer: `You can sponsor your spouse, common-law partner, conjugal partner, dependent children, parents, grandparents, and in some cases, other relatives if you meet certain conditions.`,
    showAns: false,
  },
  {
    id: "2",
    question: "What are the requirements to be a sponsor?",
    answer: `To be a sponsor, you must be a Canadian citizen or a permanent resident, at least 18 years old, residing in Canada, and have enough income to support the family members you intend to sponsor.`,
    showAns: false,
  },
  {
    id: "3",
    question:
      "How long am I financially responsible for the family member I sponsor?",
    answer: `The length of financial responsibility varies depending on the relationship. For spouses, common-law, or conjugal partners, it's usually three years. For dependent children, it can be 10 years or until the child turns 25, whichever comes first.`,
    showAns: false,
  },
  {
    id: "4",
    question: "Can I sponsor someone if I live outside Canada?",
    answer: `You must demonstrate your intention to live in Canada when the person you sponsor becomes a permanent resident. Canadian citizens can sponsor their spouse, common-law partner, conjugal partner, or dependent children while living abroad provided they intend to return to Canada. `,
    showAns: false,
  },
  {
    id: "5",
    question:
      "Can I sponsor my parents or grandparents if I do not meet the income requirement?",
    answer: `You must meet minimum necessary income levels to sponsor your parents or grandparents. If you do not meet these requirements alone, you may include the income of a co-signer.`,
    showAns: false,
  },
  {
    id: "6",
    question: "How long does the Family Sponsorship process take?",
    answer: `Processing times for family sponsorship vary based on the visa office and the complexity of your case. Lodestone Immigration can provide current estimates and help streamline the process.`,
    showAns: false,
  },
  {
    id: "7",
    question: "Can my sponsored family member work in Canada?",
    answer: `Yes, once they become permanent residents, they are entitled to live, work, and study anywhere in Canada.`,
    showAns: false,
  },
  {
    id: "8",
    question: "Is there a cap on how many family members I can sponsor?",
    answer: `There is no cap on sponsoring spouses, partners, dependent children, or parents/grandparents. However, there are caps on the number of parents and grandparents sponsored each year, so it's essential to apply as soon as possible.`,
    showAns: false,
  },
  {
    id: "9",
    question:
      "What happens if my situation changes after I submit my sponsorship application?",
    answer: `You must inform Immigration, Refugees and Citizenship Canada (IRCC) of any changes in your circumstances to avoid complications with your application.`,
    showAns: false,
  },
  {
    id: "10",
    question:
      "How can Lodestone Immigration assist me with Family Sponsorship?",
    answer: `Lodestone Immigration offers comprehensive assistance with your Family Sponsorship application, including eligibility assessment, document preparation, application submission, and guidance throughout the process.`,
    showAns: false,
  },
];
