export const TabData = [
  {
    id: "1",
    title: "Virtual Consultation Sessions",
    text: `Embrace the convenience and expertise of Lodestone Immigration without leaving your home with our Virtual Consultation Sessions. Tailored to meet the demands of your busy schedule, these sessions connect you with our experienced RCICs who are ready to offer personalized guidance on your immigration pathway. Utilizing the latest technology for seamless communication, we ensure that every detail of your case is meticulously addressed. Start your journey to Canadian shores with the full support of Lodestone Immigration, all from the comfort and safety of your virtual space!
   
    However, please note that this service isn't for reviewing applications or profiles. 
    `,
  },
  {
    id: "2",
    title: "Comprehensive 60-Minute Session",
    text: `Set the foundation for your Canadian immigration journey with our Comprehensive 60-Minute Session at Lodestone Immigration. Perfect for those new to the intricacies of the process, this extensive consultation with our expert Regulated Canadian Immigration Consultant offers a deep dive into your options. We meticulously tailor the session to explore every possible pathway, providing clarity and detailed guidance on every phase of the journey—from preparing your initial application to the moment you step foot in Canada. Entrust your aspirations to us, and we'll map out the route to your new life.    `,
  },
  {
    id: "3",
    title: "Focused 30-Minute Session",
    text: `Our Focused 30-Minute Session is crafted for individuals who come equipped with precise questions about Canadian immigration. It's the ideal choice for gaining clear, succinct answers and actionable advice when you don't require an in-depth exploration of the entire immigration process. This session cuts straight to the heart of your concerns, providing targeted insights to assist with your immediate needs. Should your situation call for a more detailed discussion, we recommend opting for our Comprehensive 60-Minute Session to cover all aspects of your immigration journey.    `,
  },
  {
    id: "4",
    title: "Initial Consultation Credit",
    text: `At Lodestone Immigration, we value your decision to choose our expertise for your immigration needs. That's why we offer an Initial Consultation Credit as a token of our commitment to you. If you decide to retain our services within 10 business days following your initial consultation, we will honor this commitment by applying the full consultation fee as a credit toward your future professional service fees. This approach ensures that your investment in our initial consultation serves as a foundational step in our continued partnership.`,
  },
];
