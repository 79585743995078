export const TabData = [
  {
    id: "1",
    title: "Role of Commissioners and Notaries in Ontario",
    text: `Safeguarding Integrity: In Ontario, the roles of commissioners and notaries are pivotal in protecting the public from fraud. They serve a fundamental purpose in verifying the integrity of documents crucial for commercial and legal transactions. Through their official capacities, they uphold the authenticity that is essential to the trust and reliability of these documents.`,
  },
  {
    id: "2",
    title: "The Authority of a Commissioner for Taking Affidavits",
    text: `Administering Legal Oaths: A commissioner for taking affidavits is endowed with the authority to take affidavits and to administer oaths, affirmations, or declarations. This service is a vital component of the legal process, ensuring that written statements used in court proceedings or other legal matters are validated under oath.`,
  },
  {
    id: "3",
    title: "Arranging for Commissioner of Oaths’ Services",
    text: `Appointment-Only Services: Lodestone Immigration provides Commissioner of Oaths’ services to individuals requiring the verification of documents for legal matters. To maintain the highest standards of service and attention to your legal needs, these services are rendered in person and strictly by appointment.`,
  },
  {
    id: "4",
    title: "Contact Lodestone Immigration for Your Legal Documentation Needs",
    text: `Scheduling Your Appointment: For those requiring the services of a commissioner for taking affidavits or other related legal document verification, Lodestone Immigration is here to assist. Contact us to schedule an appointment, and our qualified staff will be prepared to provide you with professional and efficient service tailored to your legal documentation needs.`,
  },
];
