import React, { useEffect, useState } from "react";
import styles from "./Testimonials.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import stars from "./customerstars.svg";
import "./Testimonials.css";
import "swiper/css";
import "swiper/css/navigation";

const Testimonial = () => {
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );
  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  return (
    <div className={styles.main}>
      <Container
        style={{
          backgroundImage: matches
            ? `url('/images/testimonials_background.png')`
            : `url('/images/testimonials_background_mob.png')`,
        }}
        className={styles.cont}
      >
        <Row className="pb-5 gy-4">
          <Col lg={5}>
            <div className={styles.contentCont}>
              <h2 className="h3_main">CLIENT FEEDBACK</h2>
              <h1 className="h1_main pb-2">
                Hear Directly from Our Valued Customers
              </h1>
              <a
                style={{ alignSelf: "start" }}
                target="no_blank"
                href="https://g.page/r/CSZ3pTdcyv76EBE/review"
              >
                <button className="button_main">Learn more</button>
              </a>
            </div>
          </Col>
          <Col lg={7}>
            <div className={styles.swiperCont}>
              <Swiper
                className="testimonials_swiper"
                autoplay={{ delay: 2000, disableOnInteraction: false }}
                loop
                navigation={true}
                spaceBetween={25}
                slidesPerView={1}
                modules={[Navigation, Autoplay]}
              >
                <SwiperSlide>
                  <div className={styles.box}>
                    <img src="/images/quote.png" />
                    <p className="para_main">
                      I recently had a consultation with Konesh at Lodestone
                      Immigration, and I was thoroughly impressed. His depth of
                      knowledge, clear communication, and patient demeanor made
                      the session both informative and reassuring. Konesh took
                      the time to address all my questions, providing practical
                      insights and tailored advice. It's evident that he
                      genuinely cares about assisting clients in navigating the
                      complexities of immigration. I'd highly recommend Konesh
                      and the team at Lodestone Immigration to anyone seeking
                      expert guidance on their immigration journey.
                    </p>
                    <div className={styles.review}>
                      <h2 className="h3_main">Kavitha Rapolu</h2>
                      <img src={stars} />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className={styles.box}>
                    <img src="/images/quote.png" />
                    <p className="para_main">
                      I consulted Lodestone for my relative student visa. Konesh
                      worked with us very closely and presented to me multiple
                      potential options and explained what the process would
                      look like as well as the timeline. No false promises and
                      he is very knowledgeable on the immigration process.I’m so
                      grateful to his guidance. I recommend them to those who
                      need help with immigration, the one stop definitely would
                      be Lodestone
                    </p>
                    <div className={styles.review}>
                      <h2 className="h3_main">Pradeep Appusamy</h2>
                      <img src={stars} />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className={styles.box}>
                    <img src="/images/quote.png" />
                    <p className="para_main">
                      We sought professional help with Konesh and his team at
                      Lodestone for immigration needs for a relative. Konesh and
                      his team were extremely professional and provided us with
                      multiple options to various immigration pathways available
                      in Canada . The team at lodestone has immense knowledge in
                      immigration matters and are an absolute pleasure to work
                      with.
                    </p>
                    <div className={styles.review}>
                      <h2 className="h3_main">Vaz Dave</h2>
                      <img src={stars} />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className={styles.box}>
                    <img src="/images/quote.png" />
                    <p className="para_main">
                      Over the past 5 years, I had the pleasure of working with
                      Konesh and he has been a tremendous support to our PL &
                      CSW student placement program. Konesh has been able to
                      mentor and support students with their learning goals and
                      teach them about Canadian immigration, refugee services.
                      He has used various teaching methods to connect with
                      students and demonstrate his knowledge of updated policies
                      and processes of the IRCC through client meetings. Konesh
                      is very knowledgeable at what he does and is a very
                      trusted individual.
                    </p>
                    <div className={styles.review}>
                      <h2 className="h3_main">Sobana Raj</h2>
                      <img src={stars} />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className={styles.box}>
                    <img src="/images/quote.png" />
                    <p className="para_main">
                      One of the best immigration services..One stop solution
                      for all your immigration related needs. Their
                      knowledgeable and well experienced staffs are giving
                      precise solutions and recommendations for all
                      immigration/visa related questions. Their team work
                      meticulously on each application to meet above customer
                      expectations consistently .
                      <br />
                      <br />I strongly recommend Lodestone !
                    </p>
                    <div className={styles.review}>
                      <h2 className="h3_main">Diwakar Ranganathan</h2>
                      <img src={stars} />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className={styles.box}>
                    <img src="/images/quote.png" />
                    <p className="para_main">
                      Consulting with Konesh proved invaluable. Utilizing
                      cutting-edge technology, he efficiently sourced
                      information and documents from my friend for express
                      entry, streamlining her immigration process. She was
                      elated with her experience and grateful for my
                      recommendation. I too had a stellar experience with
                      Konesh. He's a dependable and trustworthy guide for any
                      immigration endeavor.
                    </p>
                    <div className={styles.review}>
                      <h2 className="h3_main">Elaavanya Manoharan</h2>
                      <img src={stars} />
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </Col>
        </Row>
        <Row className="d-flex"></Row>
      </Container>
    </div>
  );
};

export default Testimonial;
