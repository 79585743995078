import React from "react";
import styles from "./NewVisa.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "./NewVisa.css";
import "swiper/css";
import "swiper/css/pagination";
import { Link } from "react-router-dom";

const NewVisa = () => {
  return (
    <div className={styles.main}>
      <Container>
        <div className={styles.swiperCont}>
          <Swiper
            loop
            spaceBetween={25}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              280: {
                slidesPerView: 1,
              },
              992: {
                slidesPerView: 2,
              },
              1400: {
                slidesPerView: 3,
              },
            }}
            modules={[Pagination]}
            className="visa_swiper"
          >
            <SwiperSlide>
              <div className={styles.box}>
                <div className={styles.contentCol}>
                  <h3 className="h3_main">FAMILY SPONSORSHIP</h3>
                  <Link to="/family-sponsorship">
                    <button className="button_main">Learn More</button>
                  </Link>
                </div>
                <img src="/images/visa6.webp" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.box}>
                <div className={styles.contentCol}>
                  <h3 className="h3_main">BUSINESS VISA</h3>
                  <Link to="/work-in-canada">
                    <button className="button_main">Learn More</button>
                  </Link>
                </div>
                <img src="/images/visa5.webp" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.box}>
                <div className={styles.contentCol}>
                  <h3 className="h3_main">STUDY VISA</h3>
                  <Link to="/study-in-canada">
                    <button className="button_main">Learn More</button>
                  </Link>
                </div>
                <img src="/images/visa1.webp" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.box}>
                <div className={styles.contentCol}>
                  <h3 className="h3_main">LMIA</h3>
                  <Link to="/labour-market-impact-assessment">
                    <button className="button_main">Learn More</button>
                  </Link>
                </div>
                <img src="/images/visa4.webp" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.box}>
                <div className={styles.contentCol}>
                  <h3 className="h3_main">CITIZENSHIP</h3>
                  <Link to="/canadian-citizenship">
                    <button className="button_main">Learn More</button>
                  </Link>
                </div>
                <img src="/images/visa2.webp" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.box}>
                <div className={styles.contentCol}>
                  <h3 className="h3_main">PROCEDURAL FAIRNES</h3>
                  <Link to="/procedural-fairnes-letter">
                    <button className="button_main">Learn More</button>
                  </Link>
                </div>
                <img src="/images/visa8.webp" />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </Container>
    </div>
  );
};

export default NewVisa;
