export const TabData = [
  {
    id: "1",
    title: "The Pursuit of Canadian Education",
    text: `Embarking on an educational adventure in Canada is not merely about academic advancement but also about immersing oneself in a rich cultural tapestry. Canada's reputation for high-caliber institutions extends from coast to coast, offering a wealth of knowledge and a dynamic lifestyle to students from all over the globe. With Lodestone Immigration, navigating the essential timelines—from application cycles to visa processing—is simplified, allowing you to focus on your academic and personal growth.`,
  },
  {
    id: "2",
    title: "Preparing for Your Study Permit",
    text: `To set the stage for your studies in Canada, several key documents are required. A letter of acceptance from a Designated Learning Institution (DLI) is your first step, followed by a valid passport. You must also demonstrate financial stability to cover tuition and living expenses, and you may need to provide a police certificate to verify your clean criminal record. Ensuring you're in good health is equally important, possibly requiring a medical exam. Lastly, a declaration of your intent to leave Canada upon completing your studies solidifies your study permit application.`,
  },
  {
    id: "3",
    title: "Health and Legal Readiness",
    text: `Your journey to studying in Canada includes maintaining a bill of clean health and ensuring your record aligns with Canada's security standards. Lodestone Immigration can guide you through obtaining the necessary health checks and police certifications, providing peace of mind that all legal requirements are addressed meticulously.`,
  },
  {
    id: "4",
    title: "Expert Guidance on Your Side",
    text: `The path through Canadian educational immigration is intricate, filled with crucial decisions and steps. Lodestone Immigration is equipped to offer you a personalized service experience, whether it's full representation you seek, a final application review, or guidance for an independent application process. If you have any questions or need expert advice, our Toronto-based immigration consultants are ready to clear the way to your educational aspirations in Canada.`,
  },
];
