export const Data = [
  {
    id: "1",
    question: "What are Refused/Rejected Application Review Services?",
    answer: `These services involve a thorough analysis of your refused or rejected immigration application by Lodestone Immigration experts to identify the reasons for refusal and to provide strategic advice on the possibility of reapplication or appeal.`,
    showAns: false,
  },
  {
    id: "2",
    question:
      "Why might my Canadian immigration application be refused or rejected?",
    answer: `Applications can be refused or rejected for various reasons, including incomplete documentation, ineligibility under the immigration program, failure to meet health or security standards, or concerns about the authenticity of the information provided.`,
    showAns: false,
  },
  {
    id: "3",
    question: "What should I do if my application is refused or rejected?",
    answer: `It's important to understand the specific reasons for refusal. Lodestone Immigration can review your case to provide insights and recommendations for your next steps.`,
    showAns: false,
  },
  {
    id: "4",
    question:
      "How can Lodestone Immigration help with my refused or rejected application?",
    answer: `We can help by reviewing the decision made on your application, advising on the merits of a potential reapplication or appeal, and assisting in preparing and submitting your new application or appeal documentation.`,
    showAns: false,
  },
  {
    id: "5",
    question: "Can I reapply after my application is refused or rejected?",
    answer: `Yes, you can reapply for Canadian immigration after a refusal or rejection, but it's critical to address the reasons for the initial refusal in your new application.`,
    showAns: false,
  },
  {
    id: "6",
    question:
      "Is there a time limit for reviewing a refused or rejected application?    ",
    answer: `While there's no strict time limit for reviewing a refusal, it's best to start the review process as soon as possible, especially if you plan to reapply or appeal the decision.`,
    showAns: false,
  },
  {
    id: "7",
    question:
      "What is the difference between an application review and an appeal?",
    answer: `An application review involves evaluating your refused or rejected application to determine why it was not successful. An appeal is a formal request to have the decision reviewed by a higher authority or court.`,
    showAns: false,
  },
  {
    id: "8",
    question: "Can Lodestone Immigration represent me in an appeal process?",
    answer: `Yes, Lodestone Immigration can provide representation and support throughout the appeal process, should that be the recommended course of action after reviewing your case.`,
    showAns: false,
  },
  {
    id: "9",
    question:
      "Will reviewing my refused or rejected application guarantee future success?",
    answer: `While a review can significantly improve the chances of success by identifying and correcting past mistakes, no service can guarantee the outcome of an immigration application.`,
    showAns: false,
  },
  {
    id: "10",
    question: "How long does the review process take?",
    answer: `The length of the review process can vary depending on the complexity of the refusal. Lodestone Immigration works efficiently to provide you with a detailed review in a timely manner.`,
    showAns: false,
  },
  {
    id: "11",
    question: "How do I start the review process with Lodestone Immigration?",
    answer: `Contact us directly through our website or by phone to schedule an initial consultation where we can discuss the specifics of your refused or rejected application and how we can assist.`,
    showAns: false,
  },
];
