import React, { useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import styles from "./ContactPageForm.module.css";
import axios from "axios";

const ContactPageForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("/send-contact-form-main", {
        name: name,
        email: email,
        message: message,
        phoneNum: phoneNum,
        formType: "Main Contact page form",
      })
      .then((response) => {
        if (response.data.msg === "success") {
          setError(false);
          setSuccess("Message Sent Successfully.");
          setLoading(false);
          setName("");
          setEmail("");
          setPhoneNum("");
          setMessage("");
        } else if (response.data.msg === "fail") {
          setSuccess(false);
          setError("Message failed to send, try again by reloading the page.");
          setLoading(false);
          setName("");
          setEmail("");
          setPhoneNum("");
          setMessage("");
        }
      });
  };

  return (
    <div
      style={{
        backgroundImage:
          "linear-gradient(to bottom, rgba(0, 0, 0, 0.868), rgba(0, 0, 0, 0.400)), url('/images/contact-hero.webp')",
      }}
      className={`${styles.main} main`}
    >
      <Container>
        <Row className="gy-4 gx-5">
          <Col lg={7}>
            <div className={styles.contentCol}>
              <h1 className="h1_main pb-4">
                <span>CONNECT</span> WITH US
              </h1>
              <h2 className="h2_main pb-4">
                YOUR{" "}
                <span style={{ color: "#C6262C", textDecoration: "none" }}>
                  FIRST STEP
                </span>{" "}
                TOWARDS A NEW HORIZON
              </h2>
              <p className="para_main pb-4">
                Immigration issues are intricate, often transforming seemingly
                straightforward questions into complex scenarios, with answers
                tailored to the specifics of your unique case. For personalized
                legal advice and high in-depth insights, we invite you to book
                an initial consultation with our experienced consultant.
                <br /> <br />
                It's important for us to provide accurate and responsible advice
                during a scheduled session, where our certified RCIC will
                attentively access your circumstances, pose pertinent queries,
                and guide you through your possible immigration pathways.
              </p>
              <Row style={{ width: "100%" }}>
                <Col className="pt-3 ps-0" lg={12}>
                  <div className={styles.info}>
                    <img src="/images/phone.svg" />
                    <p className="para_main">+1 647-629-7771</p>
                  </div>
                </Col>
                <Col className="pt-3 ps-0" lg={12}>
                  <div className={styles.info}>
                    <img src="/images/mail.svg" />
                    <p className="para_main">info@lodestone.com</p>
                  </div>
                </Col>
                <Col className="pt-3 pb-1 ps-0" lg={12}>
                  <div className={styles.info}>
                    <img src="/images/location.svg" />
                    <p className="para_main">*All visits by appointment only</p>
                  </div>
                </Col>
                <Col className="pt-4 ps-0" lg={12}>
                  <a
                    target="no_blank"
                    href="https://calendly.com/lodestoneimmigration"
                  >
                    <button className="button_main">Book Consultation</button>
                  </a>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={5}>
            <div className={styles.formCol}>
              <h1 className="h1_main pb-2">Let’s get in touch!</h1>
              <p className="para_main pb-5">
                Let’s discuss your project and find out what we
              </p>
              <form onSubmit={formSubmitHandler}>
                <input
                  maxLength="12"
                  type="text"
                  placeholder="Your Full Name"
                  pattern=".{3,}"
                  required
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />
                <input
                  maxLength="36"
                  type="email"
                  placeholder="Email"
                  pattern=".{3,}"
                  required
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
                <input
                  maxLength="36"
                  type="text"
                  placeholder="Phone Number"
                  pattern=".{3,}"
                  required
                  value={phoneNum}
                  onChange={(event) => setPhoneNum(event.target.value)}
                />
                <input
                  style={{ resize: "none" }}
                  as="textarea"
                  maxLength="800"
                  type="text"
                  placeholder="Your Message"
                  required
                  value={message}
                  onChange={(event) => setMessage(event.target.value)}
                />
                {loading ? (
                  <div className="row mt-3">
                    <div className="col-12">
                      <p>
                        <Spinner
                          animation="border"
                          role="status"
                          style={{ color: "white" }}
                        />
                      </p>
                    </div>
                  </div>
                ) : success ? (
                  <div className="row mt-3">
                    <div className="col-12">
                      <p className="para_main text-start">{success}</p>
                    </div>
                  </div>
                ) : (
                  error && (
                    <div className="row mt-3">
                      <div className="col-12">
                        <p className="para_main text-start">{error}</p>
                      </div>
                    </div>
                  )
                )}
                <button className="button_main pink mt-3" type="submit">
                  Submit
                </button>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactPageForm;
