export const TabData = [
  {
    id: "1",
    title: "The Significance of Canadian Citizenship",
    text: `The Journey to Citizenship: Becoming a Canadian citizen is an honor that marks the successful integration into Canadian society. This prestigious status bestows a full suite of rights, including participating in federal elections and the privilege of a Canadian passport. The pathway to citizenship encompasses fulfilling residency requirements, demonstrating language proficiency, and passing a comprehensive citizenship test. However, the process demands meticulous attention to detail and a thorough understanding of the requirements.`,
  },
  {
    id: "2",
    title: "Prerequisites for Canadian Citizenship",
    text: `Eligibility for Citizenship: The fundamental prerequisites for Canadian citizenship involve holding permanent resident status without legal uncertainties, maintaining physical presence in Canada for a specified duration, fulfilling tax obligations, and proving language proficiency in English or French. These criteria establish your readiness to take on the rights and duties of a Canadian citizen.`,
  },
  {
    id: "3",
    title: "Proving Your Commitment",
    text: `Language and Knowledge Requirements: Mastery of either official language, English or French, is crucial for applicants aged 18 to 54, along with passing the citizenship test that covers Canada's history, values, and governance. These elements are critical in demonstrating your commitment to fully embracing Canadian culture and civic life.`,
  },
  {
    id: "4",
    title: "Expert Guidance Through the Process",
    text: `Navigating Citizenship with Lodestone Immigration: At Lodestone Immigration, we understand the nuances of the citizenship process and stand ready to guide you through each requirement. Our experienced professionals ensure you are well-informed and adequately prepared for every step, transforming the aspiration of Canadian citizenship into reality with precision and personal support.`,
  },
];
