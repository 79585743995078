import React, { useEffect, useState } from "react";
import styles from "./Hero.module.css";
import Header from "../Header/Header";
import { Col, Container, Row } from "react-bootstrap";

const Hero = ({
  img,
  mobileImg,
  home,
  about,
  services,
  text,
  blog,
  IdvCaseStudy,
  span,
  heading,
  scrollToFreeBook,
}) => {
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );
  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  return (
    <div className={styles.main}>
      <div
        style={{
          backgroundImage: matches ? `url('${img}')` : `url('${mobileImg}')`,
        }}
        className={styles.hero}
      >
        <Header scrollToFreeBook={scrollToFreeBook} />
        <div className={styles.inner}>
          <Container>
            <Row className="gy-4 ">
              {!blog && (
                <>
                  <Col style={{ margin: "0px" }} lg={7}>
                    <div className={styles.contentCont}>
                      {home && (
                        <>
                          <h2 style={{ color: "black" }} className="h2_main">
                            EXPERTISE THAT DELIVERS
                          </h2>
                          <h1
                            className={`${
                              home ? "heading_capital" : "heading_capital2"
                            }`}
                          >
                            <span>BRIDGING DREAMS</span> TO REALITY!
                          </h1>
                        </>
                      )}
                      {!home && !about && (
                        <h1
                          style={{ color: "white" }}
                          className={`${
                            home ? "heading_capital" : "heading_capital2"
                          }`}
                        >
                          <span>{span}</span> {heading}
                        </h1>
                      )}
                      {about && (
                        <>
                          <h1
                            style={{ color: "white" }}
                            className="heading_capital"
                          >
                            <span>{span}</span>
                          </h1>
                          <h1 className="h1_main">{heading}</h1>
                        </>
                      )}

                      <p
                        style={{ color: home ? "black" : "#fff" }}
                        className="para_main"
                      >
                        {text}
                      </p>
                      {services && (
                        <p
                          style={{ color: home ? "black" : "#fff" }}
                          className="para_main"
                        >
                          Navigating the path to{" "}
                          <span style={{ color: "#C02429" }}>Canadian</span>{" "}
                          immigration.
                        </p>
                      )}
                      {home && (
                        <div className={styles.iconsCont}>
                          {/* <img src="/images/award1.svg" /> */}
                          <img src="/images/award2.svg" />
                          <img src="/images/award3.svg" />
                          <img src="/images/award4.svg" />
                          <img src="/images/award5.svg" />
                          <img src="/images/award6.svg" />
                        </div>
                      )}
                      {home && (
                        <a
                          className="pt-3"
                          target="no_blank"
                          href="https://calendly.com/lodestoneimmigration"
                        >
                          <button className="button_main">
                            Book Consultation
                          </button>
                        </a>
                      )}
                    </div>
                  </Col>
                  <Col style={{ margin: "0px" }} lg={5}>
                    {home && (
                      <div className={styles.imgCol}>
                        <img
                          className="img-fluid"
                          src={"/images/hero_user.webp"}
                        />
                      </div>
                    )}
                  </Col>
                </>
              )}
              {blog && (
                <Col style={{ margin: "0px" }} lg={12}>
                  <div
                    style={{ width: "100%", height: "100%" }}
                    className="d-flex justify-content-center align-items-center flex-column"
                  >
                    {!IdvCaseStudy && (
                      <h2
                        style={{ textAlign: "center", alignSelf: "center" }}
                        className="h2_main pb-3"
                      >
                        BY LODESTONE
                      </h2>
                    )}
                    <h1
                      className={`${
                        IdvCaseStudy ? "h1_main" : "h1_main"
                      } text-center`}
                    >
                      <span>{span}</span> <br />
                      {heading}
                    </h1>
                    {text && (
                      <p
                        style={{ color: "white", padding: "0px 20% 0px 20%" }}
                        className="para_main text-center pt-4"
                      >
                        {text}
                      </p>
                    )}
                  </div>
                </Col>
              )}
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Hero;
