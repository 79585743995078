export const Data = [
  {
    id: "1",
    question: "What is a Labour Market Impact Assessment (LMIA)?",
    answer: `An LMIA is a document that Canadian employers may need to obtain before hiring a foreign worker. It assesses the impact that hiring a foreign worker would have on Canada’s labour market.`,
    showAns: false,
  },
  {
    id: "2",
    question: "Why is an LMIA required? ",
    answer: `The LMIA process ensures that employers have made sufficient efforts to hire Canadian citizens or permanent residents before offering a job to a foreign national.    `,
    showAns: false,
  },
  {
    id: "3",
    question: "How does an employer apply for an LMIA?",
    answer: `Employers must submit an application to Employment and Social Development Canada (ESDC) providing various details about the job offer and their recruitment efforts among Canadian workers.    `,
    showAns: false,
  },
  {
    id: "4",
    question: "How long does it take to get an LMIA?",
    answer: `Processing times can vary. Lodestone Immigration can provide current processing time estimates and assist employers through the application process.    `,
    showAns: false,
  },
  {
    id: "5",
    question:
      "Is an LMIA always required for a work permit?",
    answer: `No, not all work permit categories require an LMIA. Some, like the Open Work Permit or those under the International Mobility Program, are LMIA-exempt.    `,
    showAns: false,
  },
  {
    id: "6",
    question: "What is a positive vs. negative LMIA?",
    answer: `A positive LMIA indicates that there is a need for a foreign worker and that no Canadian worker is available for the job. A negative LMIA indicates that the job should be filled by a Canadian worker.    `,
    showAns: false,
  },
  {
    id: "7",
    question: "As a worker, how do I know if my job offer requires an LMIA?",
    answer: `Your Canadian employer should inform you if an LMIA is needed for your job offer. Lodestone Immigration can also help determine if your job offer falls under an LMIA-required category.    `,
    showAns: false,
  },
  {
    id: "8",
    question: "Can I apply for Permanent Residence with a positive LMIA?    ",
    answer: `Yes, a positive LMIA can support certain permanent residence applications by providing additional points under the Express Entry system.    `,
    showAns: false,
  },
  {
    id: "9",
    question: "Does receiving a positive LMIA guarantee that I will get a work permit?",
    answer: `No, a positive LMIA is one of the factors taken into consideration. Applicants must also meet the eligibility criteria for a work permit. `,
    showAns: false,
  },
  {
    id: "10",
    question: "What can I do if an LMIA application is refused?",
    answer: `Lodestone Immigration can review the refusal and assist the employer in understanding the reasons behind it, potentially reapplying with additional information or corrections.
    `,
    showAns: false,
  },
  {
    id: "11",
    question: "How can Lodestone Immigration assist with the LMIA process?",
    answer: `Lodestone Immigration provides expert assistance to employers in preparing and submitting LMIA applications, ensuring all requirements are met for a successful outcome.    `,
    showAns: false,
  },
];
