import React, { useEffect, useState } from "react";
import styles from "./Awards.module.css";
import { Col, Container, Row } from "react-bootstrap";

const Awards = () => {
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );
  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  return (
    <div
      style={{
        backgroundImage: matches
          ? `url('/images/awards_background.webp')`
          : `url('/images/awards_background_mob.webp')`,
      }}
      className={styles.main}
    >
      <Container>
        <h1 className="h1_main text-center pb-3">
          TOP 3 <span>IMMIGRATION CONSULTATION</span>
        </h1>
        <p style={{ color: "white" }} className="para_main text-center pb-5">
          Immigration is a complex and detailed process that changes constantly.
        </p>
        <div className={styles.imgCont}>
          <img src="/images/award1.svg" />
          <img src="/images/award2.svg" />
          <img src="/images/award3.svg" />
          <img src="/images/award4.svg" />
          <img src="/images/award5.svg" />
        </div>
      </Container>
    </div>
  );
};

export default Awards;
