export const TabData = [
  {
    id: "1",
    title: "Bridging Families Together in Canada",
    text: `Relocation often involves difficult choices, especially when it means leaving loved ones behind. In recognition of the importance of family, the Canadian government has made family reunification a cornerstone of its immigration policy. Through the family class sponsorship program, eligible relatives can obtain permanent residency, allowing them to live, study, and work in Canada, thereby providing a permanent solution over temporary visitor visas. `,
  },
  {
    id: "2",
    title: "Meeting Sponsorship Criteria",
    text: `Sponsoring a family member to join you in Canada requires a clear understanding of the program’s financial obligations. Sponsors must meet specific income thresholds to ensure they can support their relatives as they settle in Canada. This financial responsibility is a testament to the commitment sponsors make, highlighting the program’s focus on creating stable family units.`,
  },
  {
    id: "3",
    title: "Sponsorship Eligibility and Commitment",
    text: `As a Canadian citizen or a permanent resident aged 18 or above, you could potentially sponsor a child, spouse, or common-law partner, whether they currently reside in Canada or elsewhere. When sponsoring, you commit to providing them with financial support, which means you must have stable employment in Canada and satisfy the stipulated income guidelines. This sponsorship is a significant responsibility, evident from the undertaking agreement that you signed. Please note that the Canadian family sponsorship doesn't qualify for tax receipts.`,
  },
  {
    id: "4",
    title: "Guidance and Support from Lodestone Immigration",
    text: `Navigating the nuances of family sponsorship is complex, but you don't have to do it alone. Lodestone Immigration offers expert guidance to simplify the process. By scheduling a consultation with one of our RCICs, you’ll gain valuable insights into income requirements, the nuances of sponsoring children born abroad, and the implications of surrogacy, among other considerations. Our dedicated team is committed to bringing families together and helping you realize the dream of a unified life in Canada.`,
  },
];
