import React from "react";
import Hero from "../../components/Hero/Hero";
import { Container } from "react-bootstrap";
import Footer from "../../components/Footer/Footer";

const Terms = () => {
  return (
    <>
      <Hero
        span={"Terms & Conditions"}
        img={"/images/hero-terms.webp"}
        mobileImg={"/images/hero-terms-mob.webp"}
      />
      <div style={{ backgroundColor: "#0B0B0B" }} className="main">
        <Container>
          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}> INTRODUCTION</span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            Welcome to www.lodestoneimmigration.com, the digital home of
            Lodestone Immigration Services Inc., a proudly Canadian corporation.
            Here, we lay down the rules of the road – our Terms and Conditions.
            These are important as they govern your use of our website,
            including all the content, programs, products, and services we
            offer. Everyone who visits our site – whether just browsing,
            shopping, or engaging with us in any other way – is bound by these
            terms. So, by using our website, you’re agreeing to these rules.
            Please read them carefully. If you’re not on board with these terms,
            it’s best to stop using our site.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>DEFINITIONS</span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            Website: This refers to www.lodestoneimmigration.com, encompassing
            all its content and services.
            <br />
            We/Us/Our: That’s Lodestone Immigration Services Inc.
            <br />
            User/You/Your: That’s you – whether you’re a visitor, customer, or
            any other user of our site.
            <br />
            Services: This includes a wide range of offerings like Citizenship
            assistance, various Visa programs, informational resources like
            newsletters and blogs, and much more.
            <br />
            Content: All the material we’ve created and own on our website, like
            texts, designs, graphics, logos, and multimedia.
            <br />
            Personal Information: Any data you give us that can identify you –
            like your name, contact details, or payment information.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>YOUR USE OF OUR WEBSITE</span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            Age Requirement: You need to be at least 18 years old to use our
            site. If you’re not, please don’t use our website. Consequences of
            Violation: If you don’t stick to our terms, we might have to block
            your access to our site and services. And if you’ve purchased
            something, we can’t offer a refund in such cases. Plus, we might
            have to take legal action if necessary.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>
              YOUR CONDUCT ON OUR WEBSITE{" "}
            </span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            A. FOR LAWFUL AND RESPECTFUL USE ONLY
            <br />
            <br />
            Purpose: Our website is a space for legal, legitimate, and
            non-commercial use. Please refrain from using it for unsolicited
            advertising, mass mailings, petitions, chain letters, or any form of
            spam.
            <br />
            <br />
            B. RESPECTING OTHERS
            <br />
            <br />
            Intellectual Property: Don’t post anything that infringes on someone
            else’s intellectual property. If you do, you’re responsible for any
            consequences.
            <br />
            <br />
            Respectful Communication: Be kind and respectful. Avoid posting
            anything threatening, abusive, defamatory, invasive of privacy,
            vulgar, obscene, or otherwise offensive.
            <br />
            <br />
            No Harmful Content: Steer clear of posting harmful formulas,
            instructions, or anything that encourages illegal activities.
            <br />
            <br />
            Zero Tolerance for Harassment: Absolutely no cyberbullying,
            stalking, abuse, sexual or violent exploitation, or harassment. We
            take this seriously and will remove such content and take legal
            action if necessary.
            <br />
            <br />
            Our Discretion: We reserve the right to decide what’s appropriate
            and remove content without explanation or legal consequences. If
            your content leads to legal issues, you’ll need to cover our back
            too.
            <br />
            <br />
            Release of Liability: Remember, we’re not responsible for any
            defamatory or harmful conduct by other users. You agree to not hold
            us liable for any claims arising from such conduct.
            <br />
            <br />
            C. TECHNOLOGICAL INTEGRITY
            <br />
            <br />
            No Harmful Tech: Don’t upload viruses or anything that could harm
            our website or disrupt others’ devices. Your Responsibility: You’re
            solely responsible for any electronic communications sent from your
            devices to us.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>OUR MATERIALS</span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            A. INTELLECTUAL PROPERTY RIGHTS
            <br />
            <br />
            Ownership: All content on www.lodestoneimmigration.com and our
            services is the property of Lodestone Immigration Services Inc. This
            includes material protected under U.S. and international copyright,
            trademark, and other intellectual property laws.
            <br />
            <br />
            Usage Restrictions: Do not copy, modify, or exploit our content
            without our written consent. This includes all forms of reproduction
            or electronic dissemination, whether for personal gain or otherwise.
            <br />
            <br />
            Enforcement and Licensing: We actively monitor for misuse of our
            copyrighted material. Licensing for use starts at $5,000.
            Unauthorized use may lead to billing, access termination, and legal
            action.
            <br />
            <br />
            Limited License for Users: By accessing our site, you're granted a
            limited, revocable, non-transferable license for personal,
            non-commercial use only.
            <br />
            <br />
            Original Work Protection: Our content is a product of extensive
            effort and investment. Claiming it as your own or sharing purchased
            materials without consent is strictly prohibited.
            <br />
            <br />
            Trademarks: Our logos, taglines, names, and designs are our
            trademarks, protected by law. Misuse that causes confusion, implies
            endorsement, or discredits our brand is not allowed.
            <br />
            <br />
            Reserved Rights: All rights not explicitly granted are reserved by
            Lodestone Immigration Services Inc.
            <br />
            <br />
            B. EXPRESS WRITTEN PERMISSION
            <br />
            <br />
            Procedure: To use our content, request permission at
            info@lodestoneimmigration.com in accordance with these terms.
            <br />
            <br />
            C. FREE MATERIALS
            <br />
            <br />
            Usage: Free resources from our website are for your personal,
            non-commercial use. Editing, distributing, or copying is not
            allowed. Credit us appropriately if shared and do not claim as your
            own.
            <br />
            <br />
            D. OUR NEWSLETTER
            <br />
            <br />
            Subscription: Opting into our newsletter means agreeing to
            electronic communications and acknowledging it’s a free,
            no-obligation service. Unsubscribe anytime via the provided link or
            by contacting us.
            <br />
            <br />
            Electronic Communications: By opting in, you consent to receiving
            electronic communications from us.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>YOUR MATERIALS</span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            A. INTELLECTUAL PROPERTY
            <br />
            <br />
            You may encounter opportunities to upload, post, submit, or
            otherwise transmit material ("Your Material") to our website. By
            doing so, you affirm and guarantee that you own the material and its
            copyright, or possess explicit permission from the owner and
            copyright holder for its use on our site. The responsibility to
            verify ownership and permissions for Your Material rests solely with
            you. We are not liable for any materials you upload, post, submit,
            or transmit on our site. You bear full responsibility for any
            damages or legal issues arising from Your Material, including
            infringement claims. You agree to indemnify us against all claims
            and liabilities stemming from potential or actual copyright,
            trademark, or proprietary rights violations by Your Material. By
            uploading Your Material, you confirm compliance with laws,
            regulations, and third-party rights.
            <br />
            <br />
            B. SECURITY OF YOUR MATERIALS
            <br />
            <br />
            Be aware that uploading, posting, submitting, or transmitting Your
            Material on our site means it may be accessible to third parties. We
            do not guarantee the security of any content you share on our
            website.
            <br />
            <br />
            C. OUR LICENSE TO YOUR MATERIALS
            <br />
            <br />
            Upon uploading, posting, submitting, or transmitting Your Material
            to our site, you grant us a perpetual, irrevocable, worldwide,
            royalty-free, non-exclusive license. This license allows us to use
            Your Material for any purpose, including modification, publication,
            sublicensing, reproduction, broadcasting, selling, or distribution
            for promotional, business development, or marketing purposes, in any
            present or future medium. You grant us proprietary and intellectual
            property rights over Your Material without needing further
            permission or offering compensation. We may choose to credit you or
            not as the author/uploader of Your Material.
            <br />
            <br />
            D. YOUR ACCOUNT
            <br />
            <br />
            To access certain services, you may need to create an account with
            personal details like name and email address. For purchases, payment
            information and billing address are required. You commit to
            providing accurate, current information and not to impersonate
            others. Misrepresentation or providing false information may lead to
            legal consequences, for which you accept financial and legal
            responsibility. Use your account lawfully and within your
            jurisdiction's legal boundaries.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>TESTIMONIALS</span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            Testimonials on www.lodestoneimmigration.com are authentic,
            voluntarily provided, and reflect the experiences of our service
            users. These testimonials are not a guarantee of similar results for
            all users.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>THIRD-PARTY RESOURCES</span>
          </h1>

          <p style={{ color: "white" }} className="para_main pb-5">
            Our website, www.lodestoneimmigration.com, may include links to
            third-party websites and resources. We are not responsible for their
            functionality, accuracy, content, or policies. Links do not imply
            our endorsement or approval. These third-party sites have their own
            terms and privacy policies; it's your responsibility to review them.
            We make no warranties regarding third-party websites and are not
            liable for any loss or damage resulting from their use. Your use of
            these sites is at your own risk.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>COMMERCE</span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            This section outlines the terms and conditions related to commercial
            transactions on the Lodestone Immigration website. By engaging in
            commerce on www.Lodestoneimmigration.com, you agree to these terms,
            including our refund policy.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>A. SERVICES AND PRICING</span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            Accuracy and Changes: Despite our efforts for accuracy, there may be
            inaccuracies in service descriptions and pricing. Prices are subject
            to change.
            <br />
            Price Discrepancies: If pricing errors are discovered, we'll inform
            you promptly, offering options to proceed at the correct price or
            cancel the order.
            <br />
            Order Refusal: We reserve the right to refuse or cancel orders with
            incorrect pricing and to modify or discontinue services.
            <br />
            Acceptance of Orders: Our services are available based on acceptance
            of your order, with us reserving the right to refuse service without
            explanation.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>
              B. FINANCIAL RESPONSIBILITY
            </span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            Personal Information: Purchases require your personal and payment
            information, which must be accurate and up-to-date.
            <br />
            Authorization: You confirm your authorized use of the payment method
            and sufficient funds for the purchase.
            <br />
            Liability: You are financially liable for your purchases, including
            unauthorized use of others' payment methods.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>
              C. SAFETY OF YOUR PERSONAL INFORMATION
            </span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            Security Limitations: While we implement safety measures, we cannot
            guarantee complete security of your personal information.
            <br />
            Liability Exclusion: We are not liable for unauthorized access to
            your information, with liability limited to the purchase price or
            $100 in absence of purchases.
            <br />
            Third-Party Processors: We use third-party processors with distinct
            terms. We are not responsible for their policies, and your purchase
            implies agreement with their terms.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>
              D. SEPARATE PURCHASE AGREEMENT AND CLIENT AGREEMENT
            </span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            Additional Agreements: Purchases require entering an Additional
            Agreement (Purchase or Client Agreement).
            <br />
            Contract Formation: Agreement to the Additional Agreement forms a
            new contract, applicable alongside these Terms and Conditions.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>E. PAYMENTS AND DELIVERY</span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            Payment Authorization: We conduct standard payment checks before
            processing.
            <br />
            Order Acceptance: Orders are accepted post-payment processing.
            <br />
            Confirmation and Errors: We'll email you order confirmation; report
            any errors promptly.
            <br />
            Delivery: Digital product delivery is confirmed via email; physical
            product delivery includes additional costs and taxes, subject to
            availability and shipping delays.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>F. REFUNDS POLICY</span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            This section outlines the refunds policy for transactions made on
            the Lodestone Immigration website.
            <br />
            <br />
            A) Non-Refundable Consultation Fees: Fees paid for consultations are
            non-refundable. Upon payment, these fees are deemed earned by the
            Registered Canadian Immigration Consultant (RCIC) in exchange for
            their professional advice and time. Because we have invested
            significant time, education, creativity and effort in creating our
            Services.
            <br />
            <br />
            B) General Refund Conditions: Except for the non-refundable
            consultation fees, if you are dissatisfied with any of the other
            services purchased from our website, you may be eligible for a
            refund. The eligibility for such refunds will be determined by the
            following conditions:
            <br />
            <br />
            Service Non-Performance: If a service, other than consultation, is
            not delivered as described, you may request a refund within a
            specified time period from the date of purchase.
            <br />
            Error in Transaction: In the case of an error in billing or a
            transactional mistake from our end, a full refund of the erroneous
            amount will be processed.
            <br />
            <br />
            Cancellation Policy: For services other than consultations, if you
            wish to cancel your order before the service has commenced, you may
            be eligible for a partial refund, subject to applicable cancellation
            fees.
            <br />
            <br />
            C) Refund Process: To initiate a refund, you must contact us via the
            designated channels on our website. Your request will be processed
            and reviewed in accordance with our internal policies and the
            specifics of your case.
            <br />
            <br />
            D) Refund Timing and Method: Upon approval of a refund, it will be
            processed within a certain number of business days and credited back
            to the original method of payment unless otherwise specified.
            <br />
            <br />
            E) Amendments: Lodestone Immigration reserves the right to amend
            this refunds policy at any time. Any changes will be effective
            immediately upon posting on our website.
            <br />
            <br />
            All prices and availability of services are subject to change
            without notice.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>A. GENERAL DISCLAIMER</span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            The content and services provided on our website are intended solely
            for informational and educational purposes. We make no guarantees
            about the suitability or effectiveness of our content and services
            for individual circumstances, businesses, or specific locations.
            While we strive for accuracy, we do not guarantee any particular
            outcomes from using our website or its content, including in legal,
            medical, financial, or other areas. By choosing to use our website,
            you understand and agree that Lodestone Immigration Services Inc. is
            not responsible for any direct or indirect harm, loss, or damage
            that may arise from your use of our website, its content, or our
            services. This includes, but is not limited to, any physical,
            mental, emotional, or financial injury. We expressly exclude
            liability for any such loss or damage to the fullest extent
            permissible by law.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>
              B. LEGAL AND FINANCIAL DISCLAIMER
            </span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            The information provided on our website should not be construed as
            business, financial, or legal advice. Koneswaran Thurairasah is not
            a certified professional in these fields, and the content is for
            educational purposes only. We strongly advise consulting with your
            own legal, business, or financial professionals to address your
            specific needs.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>
              C. MEDICAL, HEALTH, AND MENTAL HEALTH DISCLAIMER
            </span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            Our website's content is not medical or mental health advice.
            Koneswaran Thurairasah is not a licensed healthcare professional.
            All health-related information is for educational purposes only and
            should not replace professional medical advice. We encourage you to
            consult with healthcare professionals for any health concerns or
            decisions.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>D. TECHNOLOGY DISCLAIMER</span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            While we aim for uninterrupted access to our website, occasional
            downtime for maintenance, repairs, or updates is inevitable. We are
            not liable for any inconvenience or loss resulting from such
            interruptions. We reserve the right to modify or discontinue any
            part of our website or services without notice.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>
              E. ERRORS AND OMISSIONS DISCLAIMER
            </span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            We endeavor to provide accurate and up-to-date information on our
            website. However, it may contain inadvertent inaccuracies or errors.
            We are not liable for any such inaccuracies, errors, or omissions
            found on our website or in our content.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>F. WARRANTIES DISCLAIMER</span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            OUR WEBSITE AND ITS CONTENT ARE PROVIDED ON AN “AS IS” BASIS WITHOUT
            ANY WARRANTIES, EITHER EXPRESS OR IMPLIED. WE DISCLAIM ALL
            WARRANTIES TO THE FULLEST EXTENT PERMITTED BY LAW, INCLUDING
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
            NON-INFRINGEMENT. We do not warrant that the website will be
            uninterrupted, error-free, or free from harmful components.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>
              PERSONAL RESPONSIBILITY AND ASSUMPTION OF RISK
            </span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            We expect you to use your judgment when using our website, its
            content, and our services. It’s important to understand that you do
            so at your own risk.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>LIMITATION OF LIABILITY</span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            We need you to agree that our company, including any subsidiaries or
            affiliates, as well as our directors, officers, and employees, will
            not be responsible for any types of damages that may arise from your
            use of our website, its content, or services. This includes any
            indirect, incidental, or consequential damages. We’re not liable for
            injuries or medical issues resulting from the use of our website.
            This clause applies even if our company is aware of the possibility
            of such damages.
            <br />
            <br />
            In the rare case of technical issues like service interruptions or
            computer viruses, we’re also not responsible for any resultant
            damages. The same applies to any third-party actions that affect
            your data or property.
            <br />
            <br />
            In regions where these limitations are not permitted, our liability
            is restricted to the greatest extent allowed by law. If you haven't
            made any purchase from us, our maximum liability is capped at 100
            Canadian Dollars (CAD).
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>INDEMNIFICATION</span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            If our website, content, or services are involved in any legal
            issues because of your actions, you agree to cover any losses or
            expenses we might face. This includes legal fees. You’ll also need
            to cooperate with us fully in handling these matters. We hold the
            right to handle any claims against us as we see fit.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>TERMINATION</span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            We reserve the right to cut off your access to our website at any
            time, without any prior notice. Our decision in this regard is
            final.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>GOVERNANCE</span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            These Terms and Conditions are governed by the laws of Ontario,
            Canada. Any legal proceedings related to these terms will take place
            in Ontario.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>
              DISPUTE RESOLUTION, LITIGATION EXPENSES
            </span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            If a dispute arises, we hope to settle it amicably. Failing that,
            arbitration in Ontario will be the next step, and the decision of
            the arbitrator will be final. This arbitration must be initiated
            within a year of the dispute.
            <br />
            <br />
            In any legal actions related to these terms, the winning party will
            be compensated for reasonable legal expenses.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>ENTIRE AGREEMENT, WAIVER</span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            These Terms and Conditions, along with our Privacy Policy and
            Disclaimer, form our entire agreement with you. No waiver of any
            term will be effective unless in writing and signed by an authorized
            officer.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>NOTICES</span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            We require all notices, requests, demands, and other important
            communications related to these Terms and Conditions to be made in
            writing. Please send these documents through Canada Post to the
            following address for reliable and official record-keeping:
            <br />
            <br />
            Lodestone Immigration Services Inc.
            <br />
            410 Bronte St. S, Suite 213,
            <br />
            Milton, ON L9T 0H8
            <br />
            Canada
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>SEVERABILITY</span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            In the event any part of these Terms and Conditions is found to be
            invalid, void, or unenforceable under the law, such findings will
            not affect the validity and enforceability of the remaining
            provisions. The rest of these Terms and Conditions will continue to
            be in full effect, ensuring that our mutual agreement remains strong
            and operative.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>ASSIGNMENT</span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            You acknowledge that these Terms and Conditions, and the rights
            granted hereunder, are non-transferable by you. Any attempt by you
            to assign, delegate, sublicense, or transfer your rights is strictly
            prohibited and shall be deemed null and void. This measure is to
            ensure the integrity and specificity of our agreement.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>
              CHANGES TO THESE TERMS AND CONDITIONS
            </span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            We reserve the right to modify or amend these Terms and Conditions
            at our sole discretion. It is your responsibility to review these
            Terms and Conditions regularly for any changes. Changes will take
            effect immediately upon their posting on this site. Your continued
            use of our website after any such changes indicates your acceptance
            of the new Terms and Conditions. We will endeavor to notify you of
            any significant changes through a notice on our site, but this is
            not an obligation. For your convenience, please review this page
            periodically.
            <br />
            <br />
            If you have any questions or need further clarification about these
            Terms and Conditions, please don't hesitate to reach out to us at
            info@lodestoneimmigration.com. We're here to help and ensure that
            your experience with us is clear, fair, and positive.
          </p>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
