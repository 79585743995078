import React from "react";
import Hero from "../../components/Hero/Hero";
import WhyLodestone from "../../components/WhyLodestone/WhyLodestone";
import Licenses from "../../components/Licenses/Licenses";
import Footer from "../../components/Footer/Footer";
import Testimonial from "../../components/Testimonials/Testimonials";
import Vision from "../../components/Vision/Vision";
import Member from "../../components/Member/Member";
import Awards from "../../components/Awards/Awards";
import AboutIdvSec from "../../components/AboutIdvSec_/AboutIdvSec";

const About = () => {
  return (
    <>
      <Hero
        span="BRIDGING DREAMS"
        text={`Guiding your journey to Canadian shores with expertise and care.`}
        img={"/images/about-hero.webp"}
        mobileImg={"/images/about-hero-mob.jpg"}
        about={true}
      />
      <Licenses noText={true} />
      <AboutIdvSec />
      <Vision />
      <Member />
      <Awards />
      <WhyLodestone />
      <Testimonial />
      <Footer />
    </>
  );
};

export default About;
