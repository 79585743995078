import React from "react";
import ImgByContent from "../../components/ImgByContent/ImgByContent";
import Hero from "../../components/Hero/Hero";
import Footer from "../../components/Footer/Footer";
import Awards from "../../components/Awards/Awards";
import { Data } from "./Data";
import Testimonial from "../../components/Testimonials/Testimonials";
import Blogs from "../../components/Blogs/Blogs";
import IdvServicesTabs from "../../components/IdvServicesTabs/IdvServicesTabs";
import FAQs from "../../components/FAQs/FAQs";
import { TabData } from "./TabData";

const ConsultationAdvisory = () => {
  return (
    <>
      <Hero
        span="CONSULTATION"
        heading={"& ADVISORY SERVICES"}
        img={"/images/consultation-advisory-hero.webp"}
        mobileImg={"/images/consultation-advisory-mob-hero.webp"}
      />
      <ImgByContent
        gradientSpecific={"linear-gradient(to bottom, #080707, #080707, #121315)"}
        reverse={true}
        span={"CONSULTATION "}
        heading={"& ADVISORY SERVICES"}
        img={"/images/consultation_advisory2.webp"}
        text={`At Lodestone Immigration, our Consultation & Advisory Services are designed to offer clarity and guidance on your journey to Canadian shores. With a team of knowledgeable professionals led by seasoned RCICs, we delve deep into your unique situation, providing personalized advice and strategies. From understanding the nuances of the Canadian immigration process to mapping out the best route for your aspirations, we stand with you at every step, ensuring a smooth transition to your dream destination.        `}
      />
      <IdvServicesTabs Data={TabData} />
      <Awards />
      <FAQs text="Consultation & Adivsory Services" Data={Data} />
      <Testimonial />
      <Blogs />
      <Footer />
    </>
  );
};

export default ConsultationAdvisory;
