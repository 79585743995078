export const Data = [
  {
    id: "1",
    question: "What is Permanent Residence in Canada?",
    answer: `Permanent Residence (PR) is the status of a person who is not a Canadian citizen but has been granted the right to live and work in Canada indefinitely while adhering to residency obligations.`,
    showAns: false,
  },
  {
    id: "2",
    question: "How can I apply for Canadian Permanent Residence?",
    answer: `There are several pathways to obtaining PR status in Canada, including through economic streams like Express Entry, family sponsorship, the Provincial Nominee Program, and others. Lodestone Immigration can help determine the best route for you and assist with the application process.`,
    showAns: false,
  },
  {
    id: "3",
    question:
      "What are the main requirements for obtaining Permanent Residence?",
    answer: `While requirements vary by program, they typically include factors like age, education, work experience, language ability, and adaptability, among others. It's crucial to meet the eligibility criteria for the specific immigration program you're applying under.`,
    showAns: false,
  },
  {
    id: "4",
    question: "How long does it take to become a Permanent Resident?",
    answer: `Processing times vary greatly depending on the immigration program and your individual circumstances. Lodestone Immigration can provide insights into current processing times and help expedite your application wherever possible.`,
    showAns: false,
  },
  {
    id: "5",
    question: "Can my family accompany me if I obtain Permanent Residence?",
    answer: `Yes, your spouse or common-law partner and dependent children can be included in your application for Permanent Residence.`,
    showAns: false,
  },
  {
    id: "6",
    question: "Is Permanent Residence the same as citizenship?",
    answer: `No, Permanent Residents have many of the same rights as citizens, but they can't vote or hold certain high-security clearance jobs and must meet residency obligations. PRs can typically apply for citizenship after meeting specific residency and other requirements.`,
    showAns: false,
  },
  {
    id: "7",
    question: "Do I need a job offer to obtain Permanent Residence?",
    answer: `Not necessarily. Some immigration programs require a job offer, while others, like certain Express Entry streams, do not.`,
    showAns: false,
  },
  {
    id: "8",
    question:
      "How long do I need to stay in Canada to maintain my Permanent Residence?    ",
    answer: `You need to be physically present in Canada for at least 730 days within every five-year period, but these days do not need to be continuous.`,
    showAns: false,
  },
  {
    id: "9",
    question: "What happens if my Permanent Residence application is refused?",
    answer: `If your application is refused, it's important to understand the reasons for the refusal. Lodestone Immigration can review your case, advise on whether an appeal or a reapplication is more appropriate, and assist with the next steps.`,
    showAns: false,
  },
  {
    id: "10",
    question: "Can I travel outside Canada as a Permanent Resident?",
    answer: `Yes, Permanent Residents can travel outside Canada. However, they must meet the residency obligation to maintain their status.`,
    showAns: false,
  },
  {
    id: "11",
    question:
      "How can Lodestone Immigration help me with my Permanent Residence application?",
    answer: `Our team at Lodestone Immigration offers expert guidance on eligibility, document preparation, application submission, and continuous support throughout your PR application process to ensure a smooth transition to Canadian residency.`,
    showAns: false,
  },
];
