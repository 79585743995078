import React, { useState } from "react";
import styles from "./Info.module.css";
import "./AnimationStyles.css";
import { Col, Container, Row } from "react-bootstrap";

const Info = ({ Data, gradientReverse }) => {
  const [List, setList] = useState({ faqs: Data });

  const showAnsHandler = (id) => {
    const nextArr = List.faqs.map((item) => {
      if (item.id === id) {
        return { ...item, showAns: !item.showAns };
      }
      return item;
    });
    setList({ faqs: nextArr });
  };
  return (
    <div
      style={{
        background:
          gradientReverse &&
          `linear-gradient(to top, #121315, #121315, #353638)`,
      }}
      className={styles.main}
    >
      <Container>
        <h1 className="h1_main text-center pb-3">
          <span>Guiding</span> You Step-by-Step
        </h1>
        <p style={{ color: "white" }} className="para_main text-center pb-5">
          A Proven Performance
        </p>
        <Row className="gy-4">
          <Col lg={6}>
            <div className={styles.list}>
              {List.faqs.map((item) => (
                <div className={styles.listItem}>
                  <div
                    onClick={() => showAnsHandler(item.id)}
                    className={styles.head}
                  >
                    <div
                      style={{ gap: "15px" }}
                      className="d-flex flew-row justify-content-start align-items-start"
                    >
                      <img
                        style={{ width: "55px", height: "55px" }}
                        src={item.icon}
                      />
                      <h2
                        style={{ color: item.showAns && "#EC1D23" }}
                        className="h3_main"
                      >
                        {item.question}
                      </h2>
                    </div>
                    <img
                      alt=""
                      src={
                        !item.showAns
                          ? "/images/icondown.svg"
                          : "/images/iconup.svg"
                      }
                    />
                  </div>
                  <p
                    className={`para_main faq_slide ${
                      item.showAns ? "faq_slide-in" : "faq_slide-out"
                    }`}
                  >
                    {item.answer}
                  </p>
                </div>
              ))}
            </div>
          </Col>
          <Col lg={6}>
            <div className={styles.imgCont}>
              <img src="/images/faqs.webp" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Info;
