import React from "react";
import FAQs from "../../components/FAQs/FAQs";
import Footer from "../../components/Footer/Footer";
import { Data } from "./Data";
import Header from "../../components/Header/Header";
import ContactPageForm from "../../components/ContactPageForm/ContactPageForm";

const Contact = () => {
  return (
    <>
      <Header />
      <ContactPageForm />
      <FAQs Data={Data} />
      <Footer />
    </>
  );
};

export default Contact;
