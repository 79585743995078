export const Data = [
  {
    id: "1",
    question: "What are the benefits of becoming a Canadian citizen? ",
    answer: `Canadian citizens enjoy the right to vote, run for political office, hold Canadian passports, and are not required to meet physical presence requirements to maintain their status.`,
    showAns: false,
  },
  {
    id: "2",
    question: "How do I become eligible for Canadian citizenship?",
    answer: `To be eligible, you must have permanent resident status in Canada, have lived in Canada for at least 1,095 days out of the five years before applying, file taxes if required, demonstrate language skills in English or French, and pass a citizenship test on Canadian history and values.`,
    showAns: false,
  },
  {
    id: "3",
    question: "Can I have dual citizenship as a Canadian citizen?",
    answer: `Yes, Canada allows dual citizenship. You do not have to give up your previous nationality once you become a Canadian citizen.`,
    showAns: false,
  },
  {
    id: "4",
    question: "How long does the Canadian citizenship process take?",
    answer: `The processing time for citizenship applications can vary. Lodestone Immigration can help you understand the current processing times based on your situation.`,
    showAns: false,
  },
  {
    id: "5",
    question: "Is it possible to lose Canadian citizenship?",
    answer: `Canadian citizenship is permanent; however, it can be revoked if obtained through false representation or fraud. `,
    showAns: false,
  },
  {
    id: "6",
    question: "Can my children automatically become Canadian citizens?",
    answer: `Children born in Canada are automatically citizens. Children born outside Canada to Canadian citizens may also be citizens, but conditions apply.`,
    showAns: false,
  },
  {
    id: "7",
    question: "What is the Canadian citizenship test?",
    answer: `The citizenship test is a written test that assesses your knowledge of Canadian history, values, institutions, and symbols.`,
    showAns: false,
  },
  {
    id: "8",
    question:
      "Do I need to take a language test to become a Canadian citizen? ",
    answer: `You need to provide proof of language ability in English or French, which can be through various means, such as an accredited language test or evidence of completion of education in one of the languages. `,
    showAns: false,
  },
  {
    id: "9",
    question:
      "How can Lodestone Immigration assist me with obtaining Canadian citizenship?",
    answer: `Lodestone Immigration can guide you through the entire citizenship process, including assessing your eligibility, assisting with documentation, preparation for the citizenship test, and ensuring that all application components are completed accurately.`,
    showAns: false,
  },
  {
    id: "10",
    question:
      "What should I do if my application for Canadian citizenship is refused?    ",
    answer: `If your citizenship application is refused, Lodestone Immigration can help you understand the reasons for refusal and advise on the next steps, which may include addressing the issues and reapplying.`,
    showAns: false,
  },
];
