import React from "react";
import ImgByContent from "../../components/ImgByContent/ImgByContent";
import Hero from "../../components/Hero/Hero";
import Footer from "../../components/Footer/Footer";
import Awards from "../../components/Awards/Awards";
import { Data } from "./Data";
import Testimonial from "../../components/Testimonials/Testimonials";
import Blogs from "../../components/Blogs/Blogs";
import IdvServicesTabs from "../../components/IdvServicesTabs/IdvServicesTabs";
import FAQs from "../../components/FAQs/FAQs";
import { TabData } from "./TabData";

const LMIA = () => {
  return (
    <>
      <Hero
        span="LMIA"
        text={"Labour Market Impact Assessment"}
        img={"/images/lmia-hero.webp"}
        mobileImg={"/images/lmia-mob-hero.webp"}
      />
      <ImgByContent
        gradientSpecific={
          "linear-gradient(to bottom, #080707, #080707, #121315)"
        }
        reverse={true}
        span={"LMIA"}
        heading={"- Labour Market Impact Assessment"}
        img={"/images/lmia2.webp"}
        text={`The LMIA (Labour Market Impact Assessment) is a document that an employer in Canada may need to secure before hiring a foreign worker. The Employment and Social Development Canada (ESDC) issues this assessment. The primary purpose of the LMIA is to demonstrate that there is a need for a foreign worker to fill a job and that no Canadian worker is available to do it.`}
      />
      <IdvServicesTabs Data={TabData} />
      <Awards />
      <FAQs text={"Labour Market Impact Assessment"} Data={Data} />
      <Testimonial />
      <Blogs />
      <Footer />
    </>
  );
};

export default LMIA;
