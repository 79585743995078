import React, { Fragment, useEffect, useState } from "react";
import Hero from "../../components/Hero/Hero";
import Footer from "../../components/Footer/Footer";
import Blogs from "../../components/Blogs/Blogs";
import IdvBlogSec from "../../components/IdvBlogSec/IdvBlogSec";
import { useParams } from "react-router";
import axios from "axios";
import { Spinner } from "react-bootstrap";

const IdvBlog = () => {
  const [blogsData, setBlogsData] = useState();

  const [loading, setLoading] = useState(true);

  const { id } = useParams();

  let blogAPICalledId = false;
  let allBlogsCalled = false;

  const getAllBlogs = async () => {
    if (allBlogsCalled) return;
    allBlogsCalled = true;

    setLoading(true);
    axios
      .get(`/blogs`, {})
      .then((res) => {
        if (res.data.status === "success") {
          let blog = res.data.data.find((item) => item.slug_url == id);
          setBlogsData(blog);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllBlogs();
  }, [id]);

  return (
    <>
      <Fragment>
        {loading && !blogsData ? (
          <div
            style={{ width: "100%", height: "100vh" }}
            className="d-flex justify-content-center align-items-center"
          >
            <Spinner
              style={{ color: "red", width: "120px", height: "120px" }}
            />
          </div>
        ) : (
          blogsData && (
            <>
              <Hero
                span={blogsData.title}
                blog={true}
                img={"/images/blog-hero.webp"}
                mobileImg={"/images/blog-hero-mob.webp"}
              />
              <IdvBlogSec
                blogsData={blogsData.blogs_content}
                datePublished={blogsData.published_date}
                text={blogsData.brief_paragraph}
              />
              <Blogs />
              <Footer />
            </>
          )
        )}
      </Fragment>
    </>
  );
};

export default IdvBlog;
