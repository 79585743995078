export const TabData = [
  {
    id: "1",
    title: "Canada's Work Permit Landscape",
    text: `Canada's diverse economy offers a wealth of career opportunities, attracting skilled professionals from every corner of the world. To streamline their entry into the Canadian workforce, the country's work permit system is designed with flexibility to cater to a variety of professional scenarios. Lodestone Immigration stands ready to help you decipher this system, ensuring you select the work permit that aligns perfectly with your career objectives.`,
  },
  {
    id: "2",
    title: "Varied Work Permit Options",
    text: `Open Work Permits grant you the liberty to work for any employer in Canada, providing the flexibility to sample various roles and industries. 
   
    Employer-specific Work Permits, on the other hand, are ideal for those with confirmed job offers, delineating your work terms with a particular employer. 
    
    Post-Graduation Work Permits (PGWP) For recent graduates, the PGWP, pave the way for valuable Canadian work experience, vital for future endeavors in Canada.
    `,
  },
  {
    id: "3",
    title: "Specialized Work Streams ",
    text: `Canada also offers niche programs such as: 
   
    The Global Talent Stream, which facilitates rapid access to top-tier international expertise for Canadian businesses. 
   
    The Agricultural Workers Program ensures that the agricultural sector remains robust with a consistent workforce., 
   
    The Live-in Caregiver Program is specifically designed for individuals dedicated to providing in-home care.`,
  },
  {
    id: "4",
    title: "Your Guide to the Right Permit",
    text: `Each work permit category comes with its own set of criteria and application processes, and understanding these nuances is crucial. Lodestone Immigration's experienced team provides the clarity and guidance necessary to navigate the complexities of each type. Whether your aim is to contribute to Canada's global talent pool or support its essential services, we're here to help you secure the permit that best suits your professional journey.`,
  },
];
