export const Data = [
  {
    id: "1",
    question: "How can I study in Canada as an international student?",
    answer: `To study in Canada, you'll need to be accepted by a Designated Learning Institution (DLI) and obtain a study permit. Lodestone Immigration can guide you through selecting a suitable institution and navigating the permit application process.`,
    showAns: false,
  },
  {
    id: "2",
    question: "What is a Designated Learning Institution (DLI)?",
    answer: `A DLI is a school approved by a provincial or territorial government to host international students. Lodestone Immigration can help you identify a DLI that aligns with your educational goals.`,
    showAns: false,
  },
  {
    id: "3",
    question: "Do I need a study permit for all courses?",
    answer: `A study permit is required for most courses longer than six months. Shorter programs or courses may not require a permit, but having one can be beneficial if you decide to extend your studies.`,
    showAns: false,
  },
  {
    id: "4",
    question: "Can I work while I study in Canada?",
    answer: `Yes, most study permits allow students to work part-time during the school term and full-time during scheduled breaks without a separate work permit.`,
    showAns: false,
  },
  {
    id: "5",
    question: "How can I apply for a Canadian study permit?",
    answer: `You must first receive a letter of acceptance from a DLI. Then, you can apply for a study permit online or at a Canadian visa office. Lodestone Immigration provides assistance with the entire application process.`,
    showAns: false,
  },
  {
    id: "6",
    question: "What are the financial requirements for studying in Canada?",
    answer: `You'll need to show proof of funds to cover tuition fees and living expenses for at least one year in Canada. Lodestone Immigration can advise you on the documentation required.`,
    showAns: false,
  },
  {
    id: "7",
    question: "Can I stay in Canada after completing my studies?",
    answer: `Yes, you may be eligible for a Post-Graduation Work Permit (PGWP) that allows you to work in Canada for up to three years after graduation.`,
    showAns: false,
  },
  {
    id: "8",
    question: "What happens if my study permit application is refused?      ",
    answer: `A refusal can be due to various reasons. Lodestone Immigration can help you understand the refusal and assist in reapplying correctly.`,
    showAns: false,
  },
  {
    id: "9",
    question: "Can my family accompany me to Canada while I study?",
    answer: `Yes, your spouse or common-law partner may be eligible for an open work permit, and your dependent children can attend Canadian schools.`,
    showAns: false,
  },
  {
    id: "10",
    question: "How long does the process take to get a Canadian study permit?",
    answer: `Processing times vary by country and time of year. Lodestone Immigration can provide an estimated timeline based on your specific circumstances.`,
    showAns: false,
  },
  {
    id: "11",
    question:
      "How can Lodestone Immigration assist with my Canadian study plans?",
    answer: `From choosing the right program and DLI to preparing your study permit application and setting up for success in Canada, Lodestone Immigration offers comprehensive support for your educational journey.    `,
    showAns: false,
  },
];
