import thunk from "redux-thunk";
import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { userLoginReducer } from "./reducers/userReducers";
import {
  caseStudyCreateReducer,
  caseStudyDeleteReducer,
  caseStudyDetailsReducer,
  caseStudyUpdateReducer,
  caseStudyListReducer
} from "./reducers/caseStudyReducers";
const reducer = combineReducers({
  caseStudyList: caseStudyListReducer,
  caseStudyDetails: caseStudyDetailsReducer,
  caseStudyDelete: caseStudyDeleteReducer,
  caseStudyCreate: caseStudyCreateReducer,
  caseStudyUpdate: caseStudyUpdateReducer,
  userLogin: userLoginReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
