import React, { useEffect, useState } from "react";
import styles from "./Blogs.module.css";
import { Container, Spinner } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

const Blogs = ({ noPaddingTop }) => {
  const [popularBlogs, setPopularBlogs] = useState([]);

  const [loading, setLoading] = useState(true);

  const { id } = useParams();

  let allBlogsCalled = false;

  const getAllBlogs = async () => {
    if (allBlogsCalled) return;
    allBlogsCalled = true;

    setLoading(true);
    axios
      .get(`/blogs`, {})
      .then((res) => {
        if (res.data.status === "success") {
          let Popular_blogs_data = [];
          res.data.data.forEach((item) => {
            Popular_blogs_data.push({
              blog_id: item.blog_id,
              heading: item.title,
              paragraph: item.brief_paragraph,
              slug_url: item.slug_url,
              creative: item.blog_image,
              date: item.published_date,
            });
          });
          setPopularBlogs(Popular_blogs_data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllBlogs();
  }, [id]);

  return (
    <>
      <div
        style={{ paddingTop: noPaddingTop && "0px" }}
        className={styles.main}
      >
        <Container>
          <h2 style={{ color: "#ec1d23" }} className="h2_main pb-3">
            GET TO KNOW CANADA
          </h2>
          <h1 className="h1_main pb-5">Blogs</h1>
          {loading && !popularBlogs ? (
            <div
              style={{ width: "100%", height: "100%" }}
              className="d-flex justify-content-center align-items-center"
            >
              <Spinner
                style={{ color: "red", width: "120px", height: "120px" }}
              />
            </div>
          ) : (
            popularBlogs && (
              <div className={styles.swiperCont}>
                <Swiper
                  // autoplay={{ delay: 2000, disableOnInteraction: false }}
                  loop
                  spaceBetween={35}
                  slidesPerView={1}
                  modules={[Autoplay]}
                  className="blogs_swiper"
                >
                  {popularBlogs &&
                    popularBlogs.map((item) => (
                      <SwiperSlide>
                        <div className={styles.box}>
                          <div className={styles.imgCont}>
                            <Link to={`/blogs/${item.slug_url}`}>
                              <img src={item.creative} />
                            </Link>
                          </div>
                          <div className={styles.content}>
                            <h5 className="h5_main">{item.date}</h5>
                            <h3 className="h3_main">{item.heading}</h3>
                            <p className="para_main">{item.paragraph}</p>
                            <Link
                              style={{ alignSelf: "flex-start" }}
                              to={`/blogs/${item.slug_url}`}
                            >
                              <button className="button_main">Read more</button>
                            </Link>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            )
          )}
        </Container>
      </div>
    </>
  );
};

export default Blogs;
