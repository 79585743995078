export const Data = [
  {
    id: "1",
    question: "What is a Procedural Fairness Letter?",
    answer: `A Procedural Fairness Letter (PFL) is a correspondence issued by Canadian immigration authorities to an applicant when there are concerns about their application that may lead to refusal. It offers the applicant an opportunity to address these concerns before a final decision is made.`,
    showAns: false,
  },
  {
    id: "2",
    question: "Why did I receive a Procedural Fairness Letter?",
    answer: `You may receive a PFL if the immigration officer reviewing your application has specific concerns regarding eligibility, misrepresentation, or non-compliance with the Immigration and Refugee Protection Act (IRPA) that need clarification.`,
    showAns: false,
  },
  {
    id: "3",
    question: "How should I respond to a Procedural Fairness Letter?",
    answer: `It's crucial to respond clearly and comprehensively, providing any additional information or documentation requested. Lodestone Immigration can assist you in crafting a well-substantiated and timely response.`,
    showAns: false,
  },
  {
    id: "4",
    question: "What is the deadline to respond to a PFL?",
    answer: `The deadline will be specified in the PFL. It's typically between 7 to 30 days, depending on the complexity of the information requested.`,
    showAns: false,
  },
  {
    id: "5",
    question: "Can Lodestone Immigration help me with my PFL response?",
    answer: `Yes, Lodestone Immigration can provide expert assistance in understanding the concerns raised and preparing an effective response to the PFL.`,
    showAns: false,
  },
  {
    id: "6",
    question: "What happens if I don’t respond to a PFL?",
    answer: `Failure to respond to a PFL within the given timeframe usually results in the refusal of your application based on the information initially provided.`,
    showAns: false,
  },
  {
    id: "7",
    question: "Is a PFL a refusal of my application?",
    answer: `No, a PFL is not a refusal; it's a chance to rectify potential issues. However, not addressing the concerns adequately can lead to a subsequent refusal.`,
    showAns: false,
  },
  {
    id: "8",
    question:
      "Do I need to provide new evidence when responding to a PFL?",
    answer: `You should provide new evidence or information that directly addresses the concerns raised by the immigration authorities.`,
    showAns: false,
  },
  {
    id: "9",
    question:
      "Can a PFL lead to a positive outcome for my application?",
    answer: `Yes, if you address all concerns to the satisfaction of the immigration officer, it can lead to a positive decision on your application.`,
    showAns: false,
  },
  {
    id: "10",
    question:
      "What if I need more time to respond to a PFL?",
    answer: `If you need more time to gather information or documents, Lodestone Immigration can help request an extension from the immigration authorities.`,
    showAns: false,
  },
  {
    id: "11",
    question:
      "How can Lodestone Immigration support me through a PFL?",
    answer: `Lodestone Immigration offers comprehensive support, including assessing the PFL, gathering additional documentation, crafting a persuasive response, and liaising with immigration authorities on your behalf.`,
    showAns: false,
  },
];
