import React from "react";
import ImgByContent from "../../components/ImgByContent/ImgByContent";
import Hero from "../../components/Hero/Hero";
import Footer from "../../components/Footer/Footer";
import Awards from "../../components/Awards/Awards";
import { Data } from "./Data";
import Testimonial from "../../components/Testimonials/Testimonials";
import Blogs from "../../components/Blogs/Blogs";
import IdvServicesTabs from "../../components/IdvServicesTabs/IdvServicesTabs";
import FAQs from "../../components/FAQs/FAQs";
import { TabData } from "./TabData";

const PermanentResidence = () => {
  return (
    <>
      <Hero
        span="PERMANENT"
        heading={"RESIDENCE"}
        img={"/images/permanent-residence-hero.webp"}
        mobileImg={"/images/permanent-residence-mob-hero.webp"}
      />
      <ImgByContent
        gradientSpecific={
          "linear-gradient(to bottom, #080707, #080707, #121315)"
        }
        reverse={true}
        span={"PERMANENT "}
        heading={"RESIDENCE"}
        img={"/images/permanent-residency2.webp"}
        text={`Permanent residence in Canada is a dream many aspire to, and at Lodestone Immigration, we're here to guide you through every step of that journey. With the status of a permanent resident, you're granted the privilege to live, work, or study anywhere in Canada, while enjoying most of the rights and privileges of a Canadian citizen. Our expert team, led by seasoned RCIC professionals, ensures a comprehensive understanding and streamlined process for your permanent residence application. Partner with Lodestone Immigration, and let's make Canada your forever home.        `}
      />
      <IdvServicesTabs Data={TabData} />
      <Awards />
      <FAQs text={"Permanent Residence"} Data={Data} />
      <Testimonial />
      <Blogs />
      <Footer />
    </>
  );
};

export default PermanentResidence;
