import React, { Fragment } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Services from "./pages/Services/Services";
import SpousalVisa from "./pages/FamilyVisa/FamilyVisa";
import Blog from "./pages/Blog/Blog";
import IdvBlog from "./pages/IdvBlog/IdvBlog";
import LoginScreen from "./AdminScreens/LoginScreen";
import BlogsScreen from "./AdminScreens/BlogsScreens/BlogsScreen";
import AdminScreen from "./AdminScreens/AdminScreen";
import CaseStudies from "./pages/CaseStudies/CaseStudies";
import CaseStudyEditScreen from "./AdminScreens/CaseStudyScreens/CaseStudyEditScreen";
import CaseStudyListScreen from "./AdminScreens/CaseStudyScreens/CaseStudyListScreen";
import IdvCaseStudy from "./pages/CaseStudiesTemplate/IdvCaseStudy";
import ScrollToTop from "./components/Scroll To Top/ScrollToTop";
import Contact from "./pages/Contact/Contact";
import ConsultationAdvisory from "./pages/ConsultationAdvisory/ConsultationAdvisory";
import VisitorVisa from "./pages/VisitorVisa/VisitorVisa";
import FamilyVisa from "./pages/FamilyVisa/FamilyVisa";
import PermanentResidence from "./pages/PermanentResidence/PermanentResidence";
import Study from "./pages/Study/Study";
import Work from "./pages/Work/Work";
import LMIA from "./pages/LMIA/LMIA";
import CanadianCitizenship from "./pages/CanadianCitizenship/CanadianCitizenship";
import FairnesLetter from "./pages/FairnesLetter/FairnesLetter";
import RejectedApplications from "./pages/RejectedApplications/RejectedApplications";
import Oaths from "./pages/Oaths/Oaths";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Terms from "./pages/Terms/Terms";

function App() {
  return (
    <Fragment>
      <ScrollToTop>
        <Routes>
          <Route path="/*" element={<Navigate to="/" />} />
          <Route path="/admin" element={<AdminScreen />} />
          <Route path="/admin/casestudy" element={<CaseStudyListScreen />} />
          <Route path="/admin/blogs" element={<BlogsScreen />} />
          <Route
            path="/admin/casestudy/:id/edit"
            element={<CaseStudyEditScreen />}
          />
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blogs/:id" element={<IdvBlog />} />
          <Route path="/portfolio" element={<CaseStudies />} />
          <Route path="/casestudy/:id" element={<IdvCaseStudy />} />
          <Route path="/services" element={<Services />} />
          <Route path="/consultation-and-advisory-services" element={<ConsultationAdvisory />} />
          <Route path="/visitor-super-and-business-visa" element={<VisitorVisa />} />
          <Route path="/family-sponsorship" element={<FamilyVisa />} />
          <Route path="/permanent-residence" element={<PermanentResidence />} />
          <Route path="/study-in-canada" element={<Study />} />
          <Route path="/work-in-canada" element={<Work />} />
          <Route path="/labour-market-impact-assessment" element={<LMIA />} />
          <Route path="/canadian-citizenship" element={<CanadianCitizenship />} />
          <Route path="/procedural-fairnes-letter" element={<FairnesLetter />} />
          <Route path="/review-rejected-application" element={<RejectedApplications />} />
          <Route path="/commissioner-of-oaths" element={<Oaths />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<Terms />} />
         </Routes>
      </ScrollToTop>
    </Fragment>
  );
}

export default App;
