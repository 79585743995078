export const Links1 = [
  {
    title: "Studying In Canada ",
    path: "/study-in-canada",
  },
  {
    title: `Work In Canada`,
    path: "/work-in-canada",
  },
  {
    title: "Labour Market Impact Assessment",
    path: "/labour-market-impact-assessment",
  },
  {
    title: "Visitor, Super Visa & Business Visa",
    path: "/visitor-super-and-business-visa",
  },
];
export const Links2 = [
  {
    title: "Family Sponsorship",
    path: "/family-sponsorship",
  },
  {
    title: "Permanent Residence",
    path: "/permanent-residence",
  },
];
export const Links3 = [
  {
    title: "Consultation & Advisory Services",
    path: "/consultation-and-advisory-services",
  },
  {
    title: "Procedural Fairnes Letter",
    path: "/procedural-fairnes-letter",
  },
  {
    title: "Rejected Application Review",
    path: "/review-rejected-application",
  },
  {
    title: "Commissioner Of Oaths",
    path: "/commissioner-of-oaths",
  },
  {
    title: "Canadian Citizenship",
    path: "/canadian-citizenship",
  },
];
