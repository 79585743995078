import React from "react";
import Hero from "../../components/Hero/Hero";
import Footer from "../../components/Footer/Footer";
import Testimonial from "../../components/Testimonials/Testimonials";
import Licenses from "../../components/Licenses/Licenses";
import ImgByContent from "../../components/ImgByContent/ImgByContent";
import GetConsultation from "../../components/GetConsultation/GetConsultation";
import Contactform from "../../components/Contact Form/Contactform";

const Services = () => {
  return (
    <>
      <Hero
        span="SERVICES"
        img={"/images/services-hero.webp"}
        services={true}
      />
      <ImgByContent
        gradient={true}
        span={"CONSULTATION "}
        heading={"& ADVISORY SERVICES"}
        buttonText={"Learn more"}
        buttonLink={"/consultation-and-advisory-services"}
        img={"/images/consultation_advisory.webp"}
        text={`At Lodestone Immigration, our Consultation & Advisory Services are designed to offer clarity and guidance on your journey to Canadian shores. With a team of knowledgeable professionals led by seasoned RCICs, we delve deep into your unique situation, providing personalized advice and strategies. From understanding the nuances of the Canadian immigration process to mapping out the best route for your aspirations, we stand with you at every step, ensuring a smooth transition to your dream destination.        `}
      />
      <ImgByContent
        reverse={true}
        span={"VISITOR, SUPER VISA"}
        heading={"& BUSINESS VISA"}
        buttonText={"Learn more"}
        buttonLink={"/visitor-super-and-business-visa"}
        img={"/images/visit-visa.webp"}
        text={`Navigating your way to Canada, be it for leisure, family reunions, or business, should be a seamless journey. At Lodestone Immigration, we specialize in facilitating Visitor Visas for those keen on exploring Canada's scenic beauty and cultural tapestry. For parents and grandparents longing to spend extended periods with their loved ones, our Super Visa expertise ensures prolonged family time without the hassle of frequent renewals. Additionally, for the ambitious entrepreneur or the global business traveler, our Business Visa services pave the way for fruitful endeavors in the Canadian market. Trust Lodestone Immigration to be your compass, guiding you smoothly through the intricacies of Canadian visa processes.
       
        Discover the nuances of Canada's visa offerings tailored for diverse needs. From the standard Visitor Visa, perfect for tourists and brief stays, to the Super Visa, exclusively designed for extended family visits by parents and grandparents, to the Business Visa, catering to professionals seeking to tap into Canada's dynamic market. Dive into comprehensive insights and navigate your Canadian journey with confidence.
        `}
      />
      <GetConsultation />
      <ImgByContent
        span={"FAMILY"}
        heading={"SPONSORSHIP"}
        buttonText={"Learn more"}
        buttonLink={"/family-sponsorship"}
        img={"/images/spousal-visa.webp"}
        text={`Do you aspire for your loved ones to be beside you in Canada? Family sponsorship stands as a cherished immigration program in Canada, offering Canadian citizens and permanent residents the opportunity to bring their dear ones closer. This program is a beacon of hope for families longing to reunite and flourish on Canadian soil. However, delving into the family sponsorship process requires a keen understanding of vital components like eligibility criteria, sponsorship categories, financial requisites, and processing times, among others. With the intricacies involved, the guidance of a proficient immigration consultant is indispensable.
        
        At Lodestone Immigration, we're committed to navigating you seamlessly through these complexities, ensuring you and your family embrace a bright future in Canada together.`}
      />
      <ImgByContent
        reverse={true}
        span={"PERMANENT "}
        heading={"RESIDENCE"}
        buttonText={"Learn more"}
        buttonLink={"/permanent-residence"}
        img={"/images/permanent-residency.webp"}
        text={`Permanent residence in Canada is a dream many aspire to, and at Lodestone Immigration, we're here to guide you through every step of that journey. With the status of a permanent resident, you're granted the privilege to live, work, or study anywhere in Canada, while enjoying most of the rights and privileges of a Canadian citizen. Our expert team, led by seasoned RCIC professionals, ensures a comprehensive understanding and streamlined process for your permanent residence application. Partner with Lodestone Immigration, and let's make Canada your forever home.        `}
      />
      <ImgByContent
        span={"STUDYING "}
        heading={"IN CANADA"}
        buttonText={"Learn more"}
        buttonLink={"/study-in-canada"}
        img={"/images/study-permit.webp"}
        text={`Canada is globally recognized for its outstanding education system, diverse culture, and exceptional quality of life, making it an ideal destination for international students. Pursuing your studies in Canada not only provides you with a world-class education but also opens doors to unlimited opportunities for future growth and global exposure. Whether you aim to study at a renowned university, college, or a specialized institution, Lodestone Immigration is here to guide you through every step of your Canadian educational journey. From choosing the right institution to navigating the visa process, we ensure a smooth transition to your dream academic destination in Canada.        `}
      />
      <ImgByContent
        reverse={true}
        span={"WORK "}
        heading={"IN CANADA"}
        buttonText={"Learn more"}
        buttonLink={"/work-in-canada"}
        img={"/images/work-permit.webp"}
        text={`Canada, with its booming economy and diverse sectors, stands as a beacon for global talent seeking promising career opportunities. The nation's inclusive work culture, competitive compensation, and progressive employment laws make it a top destination for professionals worldwide. However, navigating the Canadian work visa process can be intricate.
        
        Whether you're aiming for a temporary work stint or envisioning long-term employment, understanding the requirements and eligibility is pivotal. At Lodestone Immigration, we simplify this journey for you. Our team of dedicated experts will guide you through the nuances of securing the right work permit, ensuring you're well-positioned to tap into Canada's dynamic job market. Let's transform your Canadian work dream into reality        `}
      />
      <ImgByContent
        span={"LMIA"}
        heading={"- Labour Market Impact Assessment"}
        buttonText={"Learn more"}
        buttonLink={"/labour-market-impact-assessment"}
        img={"/images/lmia.webp"}
        text={`The LMIA (Labour Market Impact Assessment) is a document that an employer in Canada may need to secure before hiring a foreign worker. The Employment and Social Development Canada (ESDC) issues this assessment. The primary purpose of the LMIA is to demonstrate that there is a need for a foreign worker to fill a job and that no Canadian worker is available to do it.`}
      />
      <ImgByContent
        reverse={true}
        span={"CANADIAN "}
        heading={"CITIZENSHIP"}
        buttonText={"Learn more"}
        buttonLink={"/canadian-citizenship"}
        img={"/images/citizenship.webp"}
        text={`Numerous Canadian permanent residents, having navigated the intricacies of the immigration journey, eagerly anticipate the moment they can proudly call themselves official Canadian citizens. However, before that milestone is reached, there are several crucial steps that must be completed, ensuring the Immigration, Refugees, Citizenship Canada (IRCC) greenlights their Canadian citizenship application.        `}
      />
      <Licenses />
      <ImgByContent
        span={"PROCEDURAL "}
        heading={"FAIRNES LETTER"}
        buttonText={"Learn more"}
        buttonLink={"/procedural-fairnes-letter"}
        img={"/images/procedural.webp"}
        text={`A Procedural Fairness Letter (PFL) is a critical document within the Canadian immigration process. Issued by Immigration, Refugees, and Citizenship Canada (IRCC), this letter is a signal that there may be concerns about an applicant's pending immigration case. Rather than an outright denial, the PFL provides applicants an opportunity to address and rectify potential issues in their application. Essentially, it upholds the principle of fairness, ensuring that applicants are made aware of concerns and are given a chance to respond before a final decision is made. It's a testament to Canada's commitment to transparent and just immigration proceedings.

        Note: The PFL represents a crucial chance to submit substantial evidence backing your application. Approach this opportunity with utmost seriousness and care. A comprehensive understanding and meticulous response are imperative.
        `}
      />
      <ImgByContent
        reverse={true}
        span={"REFUSED/ REJECTED"}
        heading={"APPLICATION  REVIEW SERVICES"}
        buttonText={"Learn more"}
        buttonLink={"/review-rejected-application"}
        img={"/images/review-rejected-application.webp"}
        text={`If you've submitted an application for a Study Permit, Work Permit, or Visitor Visa and feel that IRCC may have erroneously denied your request, or if you're seeking clarity on the reasons for the refusal, we're here to help.         `}
      />
      <ImgByContent
        span={"COMMISSIONER OF"}
        heading={"OATHS"}
        buttonText={"Learn more"}
        buttonLink={"/commissioner-of-oaths"}
        img={"/images/oaths.webp"}
        text={`In Ontario, commissioners and notaries help protect people from fraud by helping to verify the integrity of documents used in commercial and legal transactions. A commissioner for taking affidavits can take affidavits and administer oaths, affirmations or declarations.
        Commissioner of Oaths’ services are offered in person by appointment only.`}
      />
      <Testimonial />
      <Contactform />
      <Footer />
    </>
  );
};

export default Services;
