export const TabData = [
  {
    id: "1",
    title: "Aspiring for Permanent Residency",
    text: `Becoming a Permanent Resident (PR) in Canada represents a significant goal for many, offering a stable and prosperous life. While the process can be complex and varies by individual circumstances, Lodestone Immigration is dedicated to making your pursuit of PR as smooth as possible. With our expert guidance, the path to securing your status in Canada is clear and achievable.`,
  },
  {
    id: "2",
    title: "The Journey to PR Status",
    text: `Eligibility Assessment: The first crucial step in your PR application is identifying the immigration program that best fits your qualifications and aspirations. 
   
    Online Application: Applications for PR are generally submitted through the IRCC's online system, a process we can help you navigate with ease. 
   
    Fees & Documentation: Timely payment of application fees and submission of accurate documentation are pivotal to avoid delays. 
    
    Biometrics & Additional Requirements: Certain applications may require a biometrics session, among other specific requirements, which we can assist you in preparing for. 
    
    Anticipating IRCC's Decision: We set realistic expectations on processing times, from the expedited Express Entry to other more time-intensive programs.
    `,
  },
  {
    id: "3",
    title: "Pathways to Permanent Residence",
    text: `Canada's PR programs are diverse, each with unique advantages. Express Entry is designed for skilled workers seeking swift processing. Provincial Nominee Programs (PNP) cater to those with specific provincial destinations in mind, while Family Sponsorship allows existing residents to bring their loved ones to join them. PR status in Canada opens the door to living, working, and studying in any province or territory, accessing social benefits, and laying down the path to citizenship. It also allows you to sponsor family members for their PR and ensures your rights under Canadian law.`,
  },
  {
    id: "4",
    title: "Tailoring Your Application ",
    text: `Each PR program has its set of requirements, but common factors include language skills, education, work experience, and age. Additional checks such as medical clearances and police certifications may also be necessary. Lodestone Immigration is here to help you navigate these requirements, supporting you in every step towards making Canada your permanent home. With our expertise, the dream of Canadian residency is not just a vision but a forthcoming reality.`,
  },
];
