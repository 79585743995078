import React from "react";
import Hero from "../../components/Hero/Hero";
import { Container } from "react-bootstrap";
import Footer from "../../components/Footer/Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <Hero
        span={"Privacy Policy"}
        img={"/images/hero-policy.webp"}
        mobileImg={"/images/hero-policy-mob.webp"}
      />
      <div style={{ backgroundColor: "#0B0B0B" }} className="main">
        <Container>
          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>INTRODUCTION</span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            Welcome to www.lodestoneimmigration.com, the official website of
            Lodestone Immigration Services Inc., a Canadian Corporation ("the
            Company"). We are dedicated to safeguarding your privacy and
            ensuring the confidentiality of your personal information.
            <br /> <br />
            This Privacy Policy is a testament to our commitment. It outlines
            the methods we employ for collecting, processing, and managing your
            personal data. We understand the importance of privacy, especially
            in the immigration sector, and we strive to uphold the highest
            standards of data protection.
            <br /> <br />
            Your engagement with our website and any personal information you
            provide to us is governed by this Privacy Policy, in addition to
            being in full compliance with all relevant Canadian Federal,
            provincial, and territorial laws. This includes adherence to the
            Personal Information Protection and Electronic Documents Act
            (PIPEDA). Our Privacy Policy extends to all visitors, customers, and
            users of our site.
            <br /> <br />
            By accessing and using our website, you signify your understanding
            of, and agreement with, this Privacy Policy. It is essential that
            you read and comprehend the terms of this policy thoroughly. Your
            continued use of our website indicates your acceptance of these
            terms, without any modifications. If, for any reason, you do not
            agree with the terms laid out in this Privacy Policy, please
            discontinue the use of this website immediately.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>DEFINITIONS</span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            In this Privacy Policy for www.lodestoneimmigration.com, certain
            terms are used with specific meanings:
            <br /> <br />
            "Website" refers to www.lodestoneimmigration.com, encompassing all
            its content, services, and digital interactions facilitated by this
            online platform.
            <br /> <br />
            "We," "Us," and "Our" denote Lodestone Immigration Services Inc.,
            the entity responsible for operating the website and offering
            various immigration-related services.
            <br /> <br />
            "User," "You," and "Your" are terms used to represent you, the
            valued visitors to our website, our esteemed customers, and any
            other individuals who utilize the services or interact with our
            digital content.
            <br /> <br />
            "Service" and "Services" include, but are not limited to, a
            comprehensive range of immigration assistance such as:
            <br /> <br />
            Citizenship Applications
            <br />
            Express Entry System Guidance
            <br />
            Provincial Nominee Programs
            <br />
            Self-Employed Person Immigration
            <br />
            Start-Up Visa Program
            <br />
            Family Class Immigration (including Spousal, Child, and Parental
            Sponsorship)
            <br />
            Caregiver Program Assistance
            <br />
            Humanitarian and Compassionate Grounds Applications
            <br />
            Business Immigration Solutions
            <br />
            Temporary Residence Services
            <br />
            Informative and promotional newsletters, insightful blogs
            <br />
            Pre-arrival services to prepare for life in Canada
            <br />
            Each of these terms plays a crucial role in defining the scope of
            our Privacy Policy and how it applies to your interaction with
            Lodestone Immigration Services Inc. Understanding these definitions
            will help you better navigate and comprehend the policy details.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>PERSONAL INFORMATION</span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            Understanding Personal Information
            <br /> <br />
            At Lodestone Immigration Services Inc., we respect your privacy and
            are committed to handling your personal data with care and
            responsibility. "Personal Information" refers to any information
            that can be used to identify you as an individual. This may include,
            but is not limited to:
            <br /> <br />
            Identifying Information: Your full name, email address, and phone
            number. Billing Information: Your billing address and credit card
            details. Visual and Written Contributions: Any photos, comments,
            images, or videos you may share. Other Personal Details: Any
            additional information you choose to provide that can be used to
            personally identify you. Voluntary Provision of Information
            <br /> <br />
            When you provide us with your Personal Information, whether it be
            through filling out forms, engaging in our services, or interacting
            with our website, you are doing so voluntarily. By submitting this
            information, you are giving us your explicit consent to collect,
            process, and retain your Personal Information in accordance with the
            terms of this Privacy Policy.
            <br /> <br />
            Consent and Control
            <br /> <br />
            We want to emphasize that the decision to provide Personal
            Information is entirely yours. Your consent to our use of your
            Personal Information is clear, specific, and unambiguous. We assure
            you that:
            <br /> <br />
            Transparency: We will always inform you about why we need your
            Personal Information and how we will use it.
            <br />
            Security: We are dedicated to ensuring the safety and security of
            your personal data.
            <br />
            Control: You retain the right to withdraw your consent at any time
            and request an update or deletion of your personal information.
            <br />
            Your trust is important to us, and we are committed to protecting
            the privacy and security of the Personal Information you choose to
            share. Should you have any questions or concerns regarding how we
            handle your Personal Information, please feel free to reach out to
            us.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>
              A. COLLECTION OF PERSONAL INFORMATION
            </span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            Our Commitment to Your Privacy
            <br /> <br />
            At Lodestone Immigration Services Inc., your privacy is paramount.
            We collect Personal Information in the ways outlined below, ensuring
            each method aligns with our commitment to your privacy and
            compliance with regulations such as the European Union’s General
            Data Protection Regulation (GDPR).
            <br /> <br />
            Direct Communication: When you engage with us through our contact
            form, blog, social media, surveys, or other communication channels,
            we may collect details like your name, email, phone number, and
            message content. This allows us to communicate effectively and
            address your queries. All collected information is retained as part
            of our communication records.
            <br /> <br />
            Opt-In Communications: If you choose to receive free resources,
            training, or other materials by "opting in," we'll collect your name
            and email address. This consent-based process is also applicable for
            email marketing, with the option for you to withdraw consent anytime
            as detailed in section G.
            <br /> <br />
            Email List Subscription: By "opting in" to our email list, we
            collect your name and email. Whether through your consent or our
            direct marketing interest, you can withdraw at any time. We also use
            email tracking to improve our marketing efforts.
            <br /> <br />
            Customer Transactions: As a customer, your transaction-related
            information like name, contact details, and payment information are
            essential for processing purchases. This information, handled
            securely, is kept no longer than necessary. Note that third-party
            processors might handle payment transactions, and we don't retain
            payment details.
            <br /> <br />
            Website Cookies: Our website uses cookies to enhance your experience
            and gather usage data. These cookies, which can be session-based or
            persistent, are anonymous but can be disabled through your browser.
            Be aware that disabling cookies might affect website functionality.
            <br /> <br />
            Usage Analytics: Technologies like Google Analytics and Facebook
            Pixels help us understand website usage patterns. This includes
            collecting data such as IP addresses, browser details, visit
            durations, and page interactions. We ensure compliance with
            third-party policy standards and align with GDPR requirements.
            <br /> <br />
            Voluntary Submission and Lawful Processing
            <br />
            We only collect your Personal Information when you voluntarily
            provide it. Our processing of this data is grounded in lawful
            reasons, fully respecting your rights and privacy under GDPR.
            <br /> <br />
            Your Consent and Acknowledgement
            <br />
            By using our website, you acknowledge your understanding of these
            practices and agree to the collection of your Personal Information
            as described. Our aim is to provide a secure, transparent, and
            compliant environment for all our users.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>
              B. HOW WE USE YOUR PERSONAL INFORMATION
            </span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            Understanding how your personal information is used is your right.
            Here’s a summary:
            <br /> <br />
            For Communication: When you reach out to us via online forms, social
            media, or other channels, we use your provided personal information
            to communicate and address your queries.
            <br /> <br />
            Programs, Products, and Marketing: If you sign up for our programs
            or products, or choose to receive our communications, we use your
            details to deliver these services and to keep you informed. This
            includes sending resources, newsletters, and marketing materials.
            Additionally, your information may assist in creating targeted
            social media content and incorporating your feedback into our
            marketing strategies.
            <br /> <br />
            Purchases and Updates: When you buy from us, your personal
            information, including payment details, is used to complete the
            transaction. We ensure not to store or disclose your financial
            information. We also use your information to keep you informed about
            your purchase and relevant updates about our services.
            <br /> <br />
            Website Experience Improvement: The non-personally identifiable
            information gathered from your website visits helps us enhance site
            functionality, diagnose technical issues, and improve overall user
            experience. No automatic decision-making is based on this
            information.
            <br /> <br />
            As the Data Controller, we determine what information is collected
            and how it’s used and stored. We may engage third-party Data
            Processors, whom we carefully select for compliance with the
            European Union’s General Data Protection Regulation (GDPR).
            <br /> <br />
            By using our website, you acknowledge and consent to the use of your
            personal information as outlined here, in line with our Privacy
            Policy.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>
              C. HOW YOUR PERSONAL INFORMATION MAY BE SHARED
            </span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            Your awareness of how we share your information is important. We
            prioritize maintaining your information’s confidentiality while
            sharing it under specific, necessary circumstances.
            <br /> <br />
            With Trusted Third Parties: We share your information with select
            trusted partners, such as our email management service, and team
            members who help run our website, and provide legal or accounting
            services. This sharing is strictly for operational purposes.
            <br /> <br />
            For Transaction Processing: For purchases, your details are shared
            with our payment processor and financial gateway, solely to
            facilitate the transaction.
            <br /> <br />
            Processing and Storage in the United States: Your information may be
            processed and stored in the United States, either by us or our
            service providers. This means it might be subject to U.S. laws and
            accessible to government and law enforcement agencies. However, any
            processing outside of Canada will adhere to the purposes we have
            specified.
            <br /> <br />
            In Case of Business Changes: We may share your information in
            scenarios like a company sale or with a joint venture or affiliate
            partner, if deemed necessary.
            <br /> <br />
            Our Commitment:
            <br /> <br />
            Your information is shared only for specific, limited purposes. It
            is never sold or given away without your consent, except for what’s
            necessary for transactions or requests. Trusted third parties are
            expected to maintain its confidentiality and not disclose it unless
            legally required. Legal and Security-Related Disclosures: While we
            strive to protect your personal information, it may be disclosed if
            legally mandated, to address security breaches of our website,
            policy violations, threats to safety or property, or if necessary to
            protect our users or the public.
            <br /> <br />
            Your use of our website signifies your acknowledgment and consent to
            these practices, as outlined in this Privacy Policy.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>
              D. STORAGE AND TRANSFER OF PERSONAL INFORMATION
            </span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            Understanding how your information is stored and handled is crucial.
            Here’s an overview:
            <br /> <br />
            Storage System: Your Personal Information is stored in a data
            management system and may be accessed by third parties assisting in
            its management and storage.
            <br /> <br />
            Location and Compliance: Our servers are located in Canada and
            adhere to Canadian laws, Ontario provincial laws, and the GDPR for
            data collected in the EU.
            <br /> <br />
            Processing in the United States: All collected information is
            processed in the United States. Information collected outside the
            U.S. is transferred there for processing and storage and may be
            transferred back for further processing.
            <br /> <br />
            International Transfers: If your data is transferred to countries
            with less protective laws than your residence, it will still be
            governed by this Privacy Policy.
            <br /> <br />
            Your use of our website implies consent to these storage and
            transfer practices.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>
              E. PROTECTION AND SECURITY OF PERSONAL INFORMATION
            </span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            We prioritize the security of your Personal Information:
            <br /> <br />
            Security Measures: We use industry-standard security measures, like
            SSL encryption on our website, and collaborate only with third
            parties meeting current safety standards.
            <br /> <br />
            Transmission Risks: Be aware that no electronic storage or
            transmission over the internet is entirely secure.
            <br /> <br />
            Responsibility in Case of Breaches: While we take measures to
            protect your data, we cannot be held responsible for unauthorized
            third-party access. In the event of a data breach, we will inform
            the appropriate authorities promptly.
            <br /> <br />
            Public Disclosure Risk: Information you voluntarily disclose online
            (e.g., on blogs, message boards) can be collected by others. Such
            disclosures are at your own risk.
            <br /> <br />
            Data Protection Officer: Considering our operations, we are not
            required to appoint a Data Protection Officer, as our activities do
            not involve large-scale, regular, and systematic processing of
            sensitive data.
            <br /> <br />
            Your use of our services signifies agreement with these security
            measures and data handling practices.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>
              F. RETENTION OF PERSONAL INFORMATION
            </span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            We are committed to handling your Personal Information responsibly:
            <br /> <br />
            Purpose-Limited Retention: Personal Information is retained only as
            long as necessary for its original purpose.
            <br /> <br />
            Business and Legal Needs: We also retain information for legitimate
            business purposes, like legal compliance, financial record-keeping,
            and dispute resolution.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>
              G. YOUR RIGHTS OVER PERSONAL INFORMATION
            </span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            You have comprehensive control over your Personal Information:
            <br /> <br />
            Rights to Access and Control: You can access, receive a copy of,
            modify, delete, erase, or limit the processing of your Personal
            Information.
            <br /> <br />
            Exercising Your Rights: To exercise these rights, you can contact us
            at no cost:
            <br /> <br />
            Address: Lodestone Immigration Services Inc., 410 Bronte St. S, Suite 213, Milton, ON L9T 0H8 Email: info@lodestoneimmigration.com Right
            to be Forgotten: You can withdraw your consent at any time. For
            email communications, simply use the unsubscribe link in any of our
            emails, or directly email us to opt-out.
            <br /> <br />
            Opting Out: After opting out or unsubscribing, you will no longer
            receive emails from us, barring any technical issues.
            <br /> <br />
            Your use of our services indicates your agreement with these terms
            regarding the retention and control of your Personal Information.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>
              EXTERNAL WEBSITES AND SOCIAL MEDIA
            </span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            External Links: Our website may contain links to third-party
            websites. We emphasize that our Privacy Policy applies solely to
            www.lodestoneimmigration.com. If you choose to visit external
            websites, it is your responsibility to review their privacy
            policies. We are not responsible for the content or privacy
            practices of these external sites.
            <br /> <br />
            Social Media Use: Our participation on social media platforms is
            governed by their respective privacy policies. We hold no
            responsibility for your interactions on these platforms and advise
            caution in your use of them.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>PASSWORD SECURITY</span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            For certain services on our website, you may need to create an
            account with a username and password. It is your responsibility to
            keep this information secure. Please inform us immediately at
            info@lodestoneimmigration.com if you suspect any unauthorized use of
            your account.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>CHILDRENS PRIVACY</span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            Our website is intended for users aged 18 and above. We comply with
            the Children’s Online Privacy Protection Act (COPPA) and do not
            knowingly collect information from children under 18.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>GOVERNANCE</span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            This Privacy Policy and your use of our website are governed by the
            laws of the Province of Ontario and the applicable federal laws of
            Canada. Legal proceedings arising from this policy will be held in
            Ontario, Canada.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>
              CHANGES TO THE PRIVACY POLICY
            </span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            Effective Date: This Privacy Policy is effective as of 16 February
            2021.
            <br />
            Amendments: We reserve the right to modify this policy at any time.
            Changes take effect immediately upon posting on our website.
            <br />
            Your Acceptance: Continued use of our website after modifications
            implies your acceptance of these changes.
            <br />
            Updates: We encourage you to periodically review this page for the
            latest information on our privacy practices.
          </p>

          <h1 className="h3_main pb-4">
            <span style={{ color: "#db2d33" }}>CONTACT US</span>
          </h1>
          <p style={{ color: "white" }} className="para_main pb-5">
            For any questions about this Privacy Policy or your personal
            information, please contact us at info@lodestoneimmigration.com.
          </p>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
