import React from "react";
import ImgByContent from "../../components/ImgByContent/ImgByContent";
import Hero from "../../components/Hero/Hero";
import Footer from "../../components/Footer/Footer";
import Awards from "../../components/Awards/Awards";
import { Data } from "./Data";
import Testimonial from "../../components/Testimonials/Testimonials";
import Blogs from "../../components/Blogs/Blogs";
import IdvServicesTabs from "../../components/IdvServicesTabs/IdvServicesTabs";
import FAQs from "../../components/FAQs/FAQs";
import { TabData } from "./TabData";

const Oaths = () => {
  return (
    <>
      <Hero
        span="COMMISSIONER OF"
        heading={"OATHS"}
        img={"/images/oaths-hero.webp"}
        mobileImg={"/images/oaths-mob-hero.webp"}
      />
      <ImgByContent
        gradientSpecific={
          "linear-gradient(to bottom, #080707, #080707, #121315)"
        }
        reverse={true}
        span={"COMMISSIONER OF"}
        heading={"OATHS"}
        img={"/images/oaths2.webp"}
        text={`In Ontario, commissioners and notaries help protect people from fraud by helping to verify the integrity of documents used in commercial and legal transactions. A commissioner for taking affidavits can take affidavits and administer oaths, affirmations or declarations.
        Commissioner of Oaths’ services are offered in person by appointment only.`}
      />
      <IdvServicesTabs Data={TabData} />
      <Awards />
      <FAQs text={"Commissioner Of Oaths"} Data={Data} />
      <Testimonial />
      <Blogs />
      <Footer />
    </>
  );
};

export default Oaths;
