import React, { useState } from "react";
import styles from "./Footer.module.css";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("/send-newsletter", {
        email: email,
        formType: "Newsletter",
      })
      .then((response) => {
        if (response.data.msg === "success") {
          setError(false);
          setSuccess("Newsletter Subscribed Successfully!");
          setLoading(false);
          setEmail("");
        } else if (response.data.msg === "fail") {
          setSuccess(false);
          setError("Email failed to send, try again by reloading the page.");
          setLoading(false);
          setEmail("");
        }
      });
  };
  return (
    <div className={styles.main}>
      <footer className={styles.footer}>
        <Container>
          <div className={styles.newsletter}>
            <Row className="gy-4">
              <Col lg={6}>
                <Row className="gy-3">
                  <Col className={styles.newsletterImgCont} lg={4}>
                    <div className={styles.imgCont}>
                      <img src="/images/newsletter.svg" />
                    </div>
                  </Col>
                  <Col lg={8}>
                    <div className={styles.text}>
                      <h3 className="h3_main pb-2">
                        Stay informed and inspired!
                      </h3>
                      <p className="para_main">
                        Subscribe to our newsletter for the latest updates,
                        insights, and expert advice on immigration. Be a part of
                        our community and never miss out on any essential news!"
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={6}>
                <div className={styles.pillCont}>
                  {loading ? (
                    <div className="row pb-4">
                      <div className="col-12">
                        <Spinner
                          animation="border"
                          role="status"
                          style={{ color: "white" }}
                        />
                      </div>
                    </div>
                  ) : success ? (
                    <div className="row pb-4">
                      <div className="col-12">
                        <p className="para_main" style={{ color: "white" }}>
                          {success}
                        </p>
                      </div>
                    </div>
                  ) : (
                    error && (
                      <div className="row pb-4">
                        <div className="col-12">
                          <p className="para_main" style={{ color: "white" }}>
                            {error}
                          </p>
                        </div>
                      </div>
                    )
                  )}
                  <form onSubmit={formSubmitHandler} className={styles.input}>
                    <input
                      required
                      type="email"
                      value={email}
                      maxLength="36"
                      placeholder="Enter Your Email..."
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <button type="submit" style={{ border: "none" }}>
                      Subscribe
                      <img src="/images/bell.svg" />
                    </button>
                  </form>
                </div>
              </Col>
            </Row>
          </div>

          <Row className="mt-5 gy-4">
            <Col lg={4}>
              <div className={styles.contentCol}>
                <img src="/images/logo.svg" />
                <p className="para_main">
                  We offer unparalleled expertise and dedicated support to pave
                  your way to Canada. A nation where freedom, peace, and
                  prosperity flourish.
                </p>
              </div>
            </Col>
            <Col lg={8}>
              <div className={styles.infoCol}>
                <div className={styles.row1}>
                  <Link to="/terms-and-conditions">
                    <h5 className="h5_main">Terms & Conditions</h5>
                  </Link>
                  <Link to="/blog">
                    <h5 className="h5_main">Blogs & News</h5>
                  </Link>
                  <Link to="/privacy-policy">
                    <h5 className="h5_main">Privacy Policy</h5>
                  </Link>
                  <Link to="/">
                    <h5 className="h5_main">Konesh</h5>
                  </Link>
                  <Link to="/contact">
                    <h5 className="h5_main">Contact</h5>
                  </Link>
                </div>
                <div className={styles.row2}>
                  <div className={styles.iconText}>
                    <img src="/images/footer_phone.png" />{" "}
                    <p className="para_main">+1 647-629-7771</p>
                  </div>

                  <div className={styles.iconText}>
                    <img src="/images/footer_email.png" />{" "}
                    <p className="para_main">info@lodestoneimmigration.com</p>
                  </div>

                  <div className={styles.iconText}>
                    <img src="/images/footer_location.png" />{" "}
                    <p className="para_main">
                      410 Bronte St. S, Suite 213, Milton, ON L9T
                      <br /> 0H8 Canada.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>

      <div className={styles.bottomRow}>
        <Container>
          <div className={styles.bottomRowInner}>
            <p className="para_main">
              © Copyright 2024 Lodestone Immigration Services Inc - All Rights
              Reserved
            </p>
            <div className={styles.socials}>
              <a
                target="_blank"
                href="https://www.facebook.com/lodestoneimmigration"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                  <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
                </svg>
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/lodestoneimmigration/"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                </svg>
              </a>
              <a href="https://twitter.com/lodestonetweet" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
                </svg>
              </a>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/lodestoneimmigration/"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
                </svg>
              </a>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
