import React, { useState } from "react";
import styles from "./IdvServicesTabs.module.css";
import { Col, Container, Row } from "react-bootstrap";

const IdvServicesTabs = ({ Data }) => {
  const [activeTab, setActiveTab] = useState("1");
  const array = Data.find((ite) => ite.id === activeTab);

  return (
    <div className={styles.main}>
      <Container>
        <Row className="gy-5 gx-lg-5">
          <Col lg={4}>
            <div className={styles.tabs}>
              {Data.map((item) => (
                <div
                  onClick={() => setActiveTab(item.id)}
                  className={`${styles.tab} ${
                    activeTab === item.id && styles.tabActive
                  }`}
                >
                  <p className="para_main">{item.title}</p>
                </div>
              ))}
            </div>
          </Col>
          <Col lg={8}>
            <div className={styles.contentCol}>
              <h1 className="h1_main">{array.title}</h1>
              <p className="para_main">{array.text}</p>
              <a
                target="no_blank"
                href="https://calendly.com/lodestoneimmigration"
              >
                <button className="button_main">Book Consultation</button>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default IdvServicesTabs;
