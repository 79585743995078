export const Data = [
  {
    id: "1",
    question: "EVALUATE",
    answer: `Dive deep with our specialists! We'll analyze your profile, offering a clear roadmap to kickstart your Canadian adventure.`,
    icon: "/images/icons/faq_icon1.svg",
    showAns: true,
  },
  {
    id: "2",
    question: "OPTIMIZE",
    answer: `Going beyond mere assessments, we spotlight areas of improvement to enhance your immigration or citizenship prospects.`,
    icon: "/images/icons/faq_icon2.svg",
    showAns: true,
  },
  {
    id: "3",
    question: "PROCEED",
    answer: `Gain insights into the application process for various immigration pathways, whether it's temporary or permanent residence.`,
    icon: "/images/icons/faq_icon3.svg",
    showAns: true,
  },
];
