import React from "react";
import styles from "./Member.module.css";
import { Col, Container, Row } from "react-bootstrap";

const Member = () => {
  return (
    <div className={styles.main}>
      <Container>
        <Row className="gy-5">
          <Col lg={6}>
            <div className={styles.contentCol}>
              <h1 className="h1_main">
                KONESWARAN THURAIRASAH CEO & PRINCIPAL RCIC
              </h1>
              <h2 className="h4_main">
                Regulated Canadian Immigration Consultant R517808 
              </h2>
              <div className={styles.paraCont}>
                <p className="para_main">
                  The driving force behind Lodestone, Koneswaran Thurairasah,
                  known as Konesh, is a distinguished Regulated Canadian
                  Immigration Consultant (RCIC) and a member in good standing
                  with the College of Immigration and Citizenship Consultants
                  (CICC). Originally from Sri Lanka and having lived extensively
                  in India, Konesh made his journey to Canada, armed with a
                  master's in Software Engineering. He first thrived in the IT
                  industry and later transitioned into real estate, assisting
                  new immigrants in achieving their home ownership dreams.
                  <br />
                  <br />
                  Having personally navigated the immigration labyrinth, Konesh
                  understands the intricacies and challenges involved. This
                  personal experience ignited his passion for guiding others
                  smoothly through the process. Dissatisfied with the lack of
                  reliable consultants he could recommend to close friends, he
                  took the initiative to become a trusted RCIC. His commitment
                  led him to be recognized as one of the top 3 Immigration
                  consultants for five consecutive years (2019-2023).
                  <br />
                  <br />
                  Konesh tailors each immigration plan to ensure a seamless
                  journey for his clients. Upon approval, he lends his expertise
                  in helping immigrants integrate seamlessly into Canadian
                  society, leveraging a robust network of professionals
                  dedicated to easing the transition. As a seasoned
                  entrepreneur, Konesh grasps the essence of business risks,
                  guiding many in launching successful ventures in Canada.
                  <br />
                  <br />
                  Beyond his immigration expertise, Konesh is a Commissioner of
                  Oaths, enabling him to commission essential documents for
                  immigration purposes. Residing in Milton, Ontario, he's a
                  proud Milton Chamber of Commerce member and, besides being a
                  Licensed Real Estate Agent, he's dedicated to helping clients
                  realize their Canadian dream.
                </p>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className={styles.imgCol}>
              <img className="img-fluid" src="/images/member.png" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Member;
