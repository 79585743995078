export const TabData = [
  {
    id: "1",
    title: "Understanding LMIA: Employer's Prerequisite",
    text: `Employer's Role in LMIA: Securing a Labour Market Impact Assessment (LMIA) is a critical step for employers in Canada looking to hire foreign workers. They must first prove that efforts to recruit a Canadian citizen or permanent resident were unsuccessful. This typically involves advertising the role within Canada for a designated period to ensure that Canadians have the opportunity to apply. Only after these efforts can an employer consider applying for an LMIA. `,
  },
  {
    id: "2",
    title: "The LMIA Decision: Impact on the Labour Market",
    text: `Positive vs. Negative LMIA: The Employment and Social Development Canada (ESDC) assesses whether the employment of a foreign worker will positively or negatively affect the Canadian labor market. A positive LMIA is issued if there is a demonstrable shortage of available Canadian workers for the position, whereas a negative LMIA indicates that the job should be filled domestically, halting the process of hiring a foreign worker.  `,
  },
  {
    id: "3",
    title: "Securing a Work Permit Through LMIA",
    text: `LMIA-Based Work Permit Process: A positive LMIA allows the employer to extend a job offer to a foreign worker, who can then apply for an employer-specific work permit. This work permit ties the employee to the employer and the job conditions outlined in the LMIA, including the work location and the role's responsibilities.`,
  },
  {
    id: "4",
    title: "Navigating LMIA with Expertise",
    text: `Complexities of LMIA: The LMIA process demands thorough documentation and a nuanced understanding of Canada's labor market needs. Both employers and foreign workers must be well-versed in the intricacies of the LMIA to ensure compliance and legitimacy. At Lodestone Immigration, our certified consultants are skilled in facilitating the LMIA process. We ensure that foreign nationals receive valid LMIAs from reputable employers, safeguarding their rights and paving the way for successful work permit applications. Engage Lodestone Immigration for adept guidance and rest assured that your LMIA-based work permit application is in expert hands.`,
  },
];
