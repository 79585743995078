import React from "react";
import Footer from "../../components/Footer/Footer";
import Hero from "../../components/Hero/Hero";
import Testimonial from "../../components/Testimonials/Testimonials";
import CaseStudiesList from "../../components/CaseStudiesList/CaseStudiesList";

const CaseStudies = () => {
  return (
    <>
      <Hero
        span={"Case Studies"}
        img={"/images/hero-case-studies.webp"}
        mobileImg={"/images/hero-case-studies-mob.webp"}
        text={`Discover How we've Paved Paths To Canadian Dreams`}
      />
      <CaseStudiesList/>
      <Testimonial/>
      <Footer />
    </>
  );
};

export default CaseStudies;
