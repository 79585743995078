import React from "react";
import styles from "./AboutIdvSec.module.css";
import { Col, Container, Row } from "react-bootstrap";

const AboutIdvSec = () => {
  return (
    <div className={styles.main}>
      <Container>
        <div
          style={{ backgroundImage: `url("/images/about_line.svg")` }}
          className={styles.box}
        >
          <Col lg={4}>
            <div className={styles.contentCol1}>
              <div style={{ height: "auto" }} className={styles.contentBox}>
                <h3 style={{ paddingLeft: "0px" }} className="h3_main">
                  Our Story
                </h3>
                <p style={{ paddingLeft: "0px" }} className="para_main">
                  Lodestone Immigration was founded on the belief that the
                  journey to a new life in Canada should be a hopeful and secure
                  experience. Established by Koneswaram Thuraircasah, a
                  dedicated Registered Canadian Immigration Consultant, we
                  embarked on our mission in April 2017 to offer reliable,
                  comprehensive, and updated legal services for aspiring
                  immigrants.
                </p>
              </div>
              <div
                style={{
                  backgroundImage: `url("/images/about_1.svg")`,
                  marginBottom: "-360px",
                }}
                className={styles.contentBox}
              >
                <h3 className="h3_main">Our Pledge</h3>
                <p className="para_main">
                  We stand as a beacon of trust and expertise, guiding families,
                  professionals, and individuals through the intricate pathways
                  of immigration. Our commitment is to turn your aspirations
                  into tangible realities, ensuring a seamless transition to
                  Canadian life.
                </p>
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <div className={styles.contentCol2}>
              <div
                style={{ backgroundImage: `url("/images/about_2.svg")` }}
                className={styles.contentBox}
              >
                <h3 className="h3_main">Our Approach</h3>
                <p className="para_main">
                  Our approach is client-centric, ensuring a personalized
                  journey for each individual. We bridge dreams to reality,
                  taking pride in our ability to demystify the legal intricacies
                  of immigration policy and procedure.
                </p>
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <div className={styles.contentCol3}>
              <div
                style={{ backgroundImage: `url("/images/about_3.svg")` }}
                className={styles.contentBox}
              >
                <h3 className="h3_main">Why Choose Us</h3>
                <p className="para_main">
                  Choose us for our unmatched expertise, compassionate service,
                  and an unwavering commitment to your Canadian dream. With
                  Lodestone Immigration, you are not just a case; you are part
                  of our ever-expanding family. Take the first step towards your
                  Canadian dream. Contact us to schedule your consultation and
                  let us guide you home.
                </p>
              </div>
            </div>
          </Col>
        </div>
        <div className={styles.mobBox}>
          <Row>
            <Col lg={4}>
              <div style={{ height: "auto" }} className={styles.contentMobBox}>
                <h3 className="h3_main">Our Story</h3>
                <p className="para_main">
                  Lodestone Immigration was founded on the belief that the
                  journey to a new life in Canada should be a hopeful and secure
                  experience. Established by Koneswaram Thuraircasah, a
                  dedicated Registered Canadian Immigration Consultant, we
                  embarked on our mission in April 2017 to offer reliable,
                  comprehensive, and updated legal services for aspiring
                  immigrants.
                </p>
              </div>
            </Col>
            <Col lg={4}>
              <div
                style={{
                  backgroundImage: `url("/images/about_1.svg")`,
                }}
                className={styles.contentMobBox}
              >
                <h3 className="h3_main">Our Pledge</h3>
                <p className="para_main">
                  We stand as a beacon of trust and expertise, guiding families,
                  professionals, and individuals through the intricate pathways
                  of immigration. Our commitment is to turn your aspirations
                  into tangible realities, ensuring a seamless transition to
                  Canadian life.
                </p>
              </div>
            </Col>

            <Col lg={4}>
              <div
                style={{
                  backgroundImage: `url("/images/about_2.svg")`,
                }}
                className={styles.contentMobBox}
              >
                <h3 className="h3_main">Our Approach</h3>
                <p className="para_main">
                  Our approach is client-centric, ensuring a personalized
                  journey for each individual. We bridge dreams to reality,
                  taking pride in our ability to demystify the legal intricacies
                  of immigration policy and procedure.
                </p>
              </div>
            </Col>
            <Col lg={4}>
              <div
                style={{
                  backgroundImage: `url("/images/about_3.svg")`,
                }}
                className={styles.contentMobBox}
              >
                <h3 className="h3_main">Why Choose Us</h3>
                <p className="para_main">
                  Choose us for our unmatched expertise, compassionate service,
                  and an unwavering commitment to your Canadian dream. With
                  Lodestone Immigration, you are not just a case; you are part
                  of our ever-expanding family. Take the first step towards your
                  Canadian dream. Contact us to schedule your consultation and
                  let us guide you home.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default AboutIdvSec;
