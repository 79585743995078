export const Data = [
  {
    id: "1",
    question: "What is a Commissioner of Oaths?",
    answer: `A Commissioner of Oaths is a person authorized to take your oath or solemn affirmation when you sign an affidavit or a statutory declaration.`,
    showAns: false,
  },
  {
    id: "2",
    question: "When do I need a Commissioner of Oaths?",
    answer: `You will require the services of a Commissioner of Oaths when you need to swear an oath or make a solemn declaration for documents such as affidavits, legal forms, or certain immigration documents in Canada.`,
    showAns: false,
  },
  {
    id: "3",
    question: "What services do Commissioners of Oaths provide?",
    answer: `Commissioners of Oaths can administer oaths, take affidavits, and certify documents as true copies of the original for use in various legal and administrative proceedings.`,
    showAns: false,
  },
  {
    id: "4",
    question:
      "Can Lodestone Immigration's Commissioner of Oaths services be used for any document?",
    answer: `Our services can be used for most documents requiring an oath or declaration. However, there are some exceptions depending on jurisdiction and document type. It's best to consult with us directly about your specific needs.`,
    showAns: false,
  },
  {
    id: "5",
    question:
      "Do I need to be physically present to use Commissioner of Oaths services?",
    answer: `Yes, you must be physically present with your identification to swear the oath or make the declaration before the Commissioner of Oaths.`,
    showAns: false,
  },
  {
    id: "6",
    question: "Can a Commissioner of Oaths refuse to commission a document? ",
    answer: `Yes, if the Commissioner has reasonable grounds to believe the document is deceptive, contains errors, or if the signatory does not understand the contents, they can refuse to commission it.`,
    showAns: false,
  },
  {
    id: "7",
    question:
      "How can I prepare for my appointment with a Commissioner of Oaths?",
    answer: `Ensure that all documents are complete and unsigned, as you must sign them in the presence of the Commissioner. Bring valid photo identification to verify your identity.`,
    showAns: false,
  },
  {
    id: "8",
    question: "Is there a fee for Commissioner of Oaths services?",
    answer: `Yes, there is typically a fee for these services. Lodestone Immigration will provide you with a fee schedule upon request.`,
    showAns: false,
  },
  {
    id: "9",
    question:
      "How do I book Commissioner of Oaths services with Lodestone Immigration?",
    answer: `Contact us through our website or call us directly to schedule an appointment. We'll guide you through the process and answer any questions you may have.`,
    showAns: false,
  },
  {
    id: "10",
    question:
      "Can Lodestone Immigration provide Commissioner of Oaths services for documents used outside Canada?",
    answer: `Yes, we can commission documents intended for use outside Canada, but it's important to verify whether additional authentication or legalization is required for the country in which the document will be used.`,
    showAns: false,
  },
  {
    id: "11",
    question:
      "What distinguishes Lodestone Immigration's Commissioner of Oaths services?",
    answer: `At Lodestone Immigration, we offer prompt, reliable, and professional Commissioner of Oaths services to ensure your documents are legally recognized and your process moves forward without delay.`,
    showAns: false,
  },
];
