import React from "react";
import ImgByContent from "../../components/ImgByContent/ImgByContent";
import Hero from "../../components/Hero/Hero";
import Footer from "../../components/Footer/Footer";
import Awards from "../../components/Awards/Awards";
import { Data } from "./Data";
import Testimonial from "../../components/Testimonials/Testimonials";
import Blogs from "../../components/Blogs/Blogs";
import IdvServicesTabs from "../../components/IdvServicesTabs/IdvServicesTabs";
import FAQs from "../../components/FAQs/FAQs";
import { TabData } from "./TabData";

const VisitorVisa = () => {
  return (
    <>
      <Hero
        span="VISITOR, SUPER VISA"
        heading={"& BUSINESS VISA"}
        img={"/images/visitor-hero.webp"}
        mobileImg={"/images/visitor-mob-hero.webp"}

      />
      <ImgByContent
        gradientSpecific={
          "linear-gradient(to bottom, #080707, #080707, #121315)"
        }
        reverse={true}
        span={"VISITOR, SUPER VISA"}
        heading={"& BUSINESS VISA"}
        img={"/images/visit-visa2.webp"}
        text={`Navigating your way to Canada, be it for leisure, family reunions, or business, should be a seamless journey. At Lodestone Immigration, we specialize in facilitating Visitor Visas for those keen on exploring Canada's scenic beauty and cultural tapestry. For parents and grandparents longing to spend extended periods with their loved ones, our Super Visa expertise ensures prolonged family time without the hassle of frequent renewals. Additionally, for the ambitious entrepreneur or the global business traveler, our Business Visa services pave the way for fruitful endeavors in the Canadian market. Trust Lodestone Immigration to be your compass, guiding you smoothly through the intricacies of Canadian visa processes.
       
        Discover the nuances of Canada's visa offerings tailored for diverse needs. From the standard Visitor Visa, perfect for tourists and brief stays, to the Super Visa, exclusively designed for extended family visits by parents and grandparents, to the Business Visa, catering to professionals seeking to tap into Canada's dynamic market. Dive into comprehensive insights and navigate your Canadian journey with confidence.
        `}
      />
      <IdvServicesTabs Data={TabData} />
      <Awards />
      <FAQs text={"Visitor, Super Visa & Business Visa"} Data={Data} />
      <Testimonial />
      <Blogs />
      <Footer />
    </>
  );
};

export default VisitorVisa;
