import React from "react";
import styles from "./Licenses.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

const Licenses = ({ noText }) => {
  return (
    <div className={styles.main}>
      <Container>
        {!noText && (
          <>
            <h1 className="h1_main text-center pb-3">
              Licenses and <span>Memberships</span>
            </h1>
            <p
              style={{ color: "white" }}
              className="para_main text-center pb-5"
            >
              Immigration is a complex and detailed process that changes
              constantly.
            </p>
          </>
        )}
        <div className={styles.boxCont}>
          <Swiper
            autoplay={{ delay: 2000, disableOnInteraction: false }}
            loop
            spaceBetween={25}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              280: {
                slidesPerView: 1,
              },
              992: {
                slidesPerView: 3,
              },
              1200: {
                slidesPerView: 5,
              },
            }}
            modules={[Autoplay]}
            className={styles.boxCont}
          >
            <SwiperSlide className={styles.swiperSlide}>
              <div className={styles.box}>
                <img src="/images/icons/accerted.webp" />
                <a
                  target="no_blank"
                  href="https://www.bbb.org/ca/on/milton/profile/immigration-consultant/lodestone-immigration-services-inc-0107-1388002 "
                >
                  <h5 className="h5_main">Verify Status</h5>
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide className={styles.swiperSlide}>
              <div className={styles.box}>
                <img src="/images/icons/cael2.webp" />
              </div>
            </SwiperSlide>
            <SwiperSlide className={styles.swiperSlide}>
              <div className={styles.box}>
                <img src="/images/icons/ontario.webp" />
              </div>
            </SwiperSlide>
            <SwiperSlide className={styles.swiperSlide}>
              <div className={styles.box}>
                <img src="/images/icons/bni.jpg" />
                <a
                  target="no_blank"
                  href="https://bnigh.com/en-CA/memberdetails?encryptedMemberId=VCKshWqBqRHDUBY2%2BMoMTw%3D%3D&cmsv3=true&name=Koneswaran+Thurairasah"
                >
                  <h5 className="h5_main">Verify Status</h5>
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide className={styles.swiperSlide}>
              <div className={styles.box}>
                <img src="https://cdn.trustpilot.net/brand-assets/4.3.0/logo-white.svg" />
                <a
                  target="no_blank"
                  href="https://www.trustpilot.com/review/lodestoneimmigration.com?_gl=1*hk8sdf*_ga*MjA1MDA5MTI1MS4xNzA5NzM3NTI2*_ga_11HBWMC274*MTcwOTc0NzU5Ni4yLjEuMTcwOTc0NzYxNS40MS4wLjA.*_ga_HC3VZEBFR4*MTcwOTc0NzYwMC4yLjEuMTcwOTc0NzYxNi40NC4wLjA.&utm_campaign=trustpilot_company_review_page&utm_medium=request_demo&utm_source=businesswebsite"
                >
                  <h5 className="h5_main">Verify Status</h5>
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide className={styles.swiperSlide}>
              <div className={styles.box}>
                <img src="/images/icons/cicc.webp" />
                <a
                  target="no_blank"
                  href="https://college-ic.ca/protecting-the-public/find-an-immigration-consultant?l=en-CA"
                >
                  <h5 className="h5_main">Verify Status</h5>
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide className={styles.swiperSlide}>
              <div className={styles.box}>
                <img src="/images/icons/license_milton.webp" />
                <a
                  target="no_blank"
                  href="https://business.miltonchamber.ca/list/member/lodestone-immigration-services-inc-2624"
                >
                  <h5 className="h5_main">Verify Status</h5>
                </a>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </Container>
    </div>
  );
};

export default Licenses;
