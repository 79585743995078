export const TabData = [
  {
    id: "1",
    title: "Visitor Visa",
    text: "Embark on an exciting Canadian adventure with a Visitor Visa, your key to experiencing the country's vibrant culture, breathtaking landscapes, and dynamic cities. Whether you're planning a short holiday, visiting family, or exploring business opportunities, Lodestone Immigration is here to ensure your application process is smooth and successful. Our expert team will guide you through the requirements, assist with documentation, and provide personalized support every step of the way, making your visit to Canada a memorable journey from start to finish.",
  },
  {
    id: "2",
    title: "Super Visa",
    text: "Reunite with your loved ones for extended periods with the Canadian Super Visa, a multi-entry visa that allows parents and grandparents of Canadian citizens and permanent residents to stay for up to two years per visit. Lodestone Immigration simplifies the Super Visa application process, offering comprehensive assistance in meeting the criteria, from securing the necessary insurance to preparing a compelling invitation from your family. Trust our expertise to navigate this valuable opportunity for family connection, ensuring long-lasting memories across generations in Canada.",
  },
  {
    id: "3",
    title: "Business Visa",
    text: "Unlock the gateway to commercial prospects with Canada's Business Visa, crafted for entrepreneurs and professionals seeking to engage with the Canadian market. Whether attending conferences, networking events, or exploring investment avenues, Lodestone Immigration provides strategic support to streamline your visa application. We ensure that your documentation aligns perfectly with the requirements, facilitating a smooth entry into Canada's thriving business landscape. Let us be the launchpad for your economic endeavors in a nation known for its welcoming business environment.",
  },
  {
    id: "4",
    title: "Exploring Visa Options",
    text: "If you're looking to navigate the complexities of various visa types and their specific offerings, Lodestone Immigration is here to help. Our seasoned RCICs are equipped with the latest insights and extensive knowledge to provide you with comprehensive guidance. Whether you're an entrepreneur, student, family member, or skilled professional, we'll help clarify which visa category best suits your needs and how to approach the application process. Reach out to us for expert assistance in charting your course to Canada.",
  },
];
