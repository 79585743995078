import React, { useEffect, useRef } from "react";
import Hero from "../../components/Hero/Hero";
import NewVisa from "../../components/NewVisa/NewVisa";
import ImgByContent from "../../components/ImgByContent/ImgByContent";
import Footer from "../../components/Footer/Footer";
import FreeBook from "../../components/FreeBook/FreeBook";
import Info from "../../components/Info/Info";
import { Data } from "./Data";
import CaseStudies from "../../components/CaseStudies/CaseStudies";
import Testimonial from "../../components/Testimonials/Testimonials";
import Blogs from "../../components/Blogs/Blogs";
import { useLocation } from "react-router-dom";

const Home = () => {
  const freeBookRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollToFreeBook) {
      setTimeout(scrollToFreeBook, 0);
    }
  }, [location]);

  const scrollToFreeBook = () => {
    if (freeBookRef.current) {
      freeBookRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Hero
        text={`Konesh at Lodestone Immigration is your anchor in the voyage to Canadian Shores`}
        img={"/images/Hero.jpg"}
        mobileImg={"/images/hero-mob.jpg"}
        home={true}
        scrollToFreeBook={scrollToFreeBook}
      />
      <NewVisa />
      <ImgByContent
        background={"#121315"}
        tagline={"Strategic Analysis, Tailored Solutions, Proven Outcomes!"}
        heading={"Your Personalized Pathway to Canada Awaits!"}
        buttonText={"Book Consultation"}
        img={"/images/imgbycontent.webp"}
        bookConsultation={true}
      />
      <Info Data={Data} />
      <FreeBook myRef={freeBookRef} />
      <CaseStudies />
      <Testimonial />
      <Blogs noPaddingTop={true} />
      <Footer />
    </>
  );
};

export default Home;
