import React from "react";
import ImgByContent from "../../components/ImgByContent/ImgByContent";
import Hero from "../../components/Hero/Hero";
import Footer from "../../components/Footer/Footer";
import Awards from "../../components/Awards/Awards";
import { Data } from "./Data";
import Testimonial from "../../components/Testimonials/Testimonials";
import Blogs from "../../components/Blogs/Blogs";
import IdvServicesTabs from "../../components/IdvServicesTabs/IdvServicesTabs";
import FAQs from "../../components/FAQs/FAQs";
import { TabData } from "./TabData";

const Study = () => {
  return (
    <>
      <Hero
        span="STUDYING"
        heading={"IN CANADA"}
        img={"/images/study-hero.webp"}
        mobileImg={"/images/study-mob-hero.webp"}
      />
      <ImgByContent
        gradientSpecific={
          "linear-gradient(to bottom, #080707, #080707, #121315)"
        }
        reverse={true}
        span={"STUDYING "}
        heading={"IN CANADA"}
        img={"/images/study-permit2.webp"}
        text={`Canada is globally recognized for its outstanding education system, diverse culture, and exceptional quality of life, making it an ideal destination for international students. Pursuing your studies in Canada not only provides you with a world-class education but also opens doors to unlimited opportunities for future growth and global exposure. Whether you aim to study at a renowned university, college, or a specialized institution, Lodestone Immigration is here to guide you through every step of your Canadian educational journey. From choosing the right institution to navigating the visa process, we ensure a smooth transition to your dream academic destination in Canada.        `}
      />
      <IdvServicesTabs Data={TabData} />
      <Awards />
      <FAQs text={"Studying In Canada"} Data={Data} />
      <Testimonial />
      <Blogs />
      <Footer />
    </>
  );
};

export default Study;
