import React from "react";
import ImgByContent from "../../components/ImgByContent/ImgByContent";
import Hero from "../../components/Hero/Hero";
import Footer from "../../components/Footer/Footer";
import Awards from "../../components/Awards/Awards";
import { Data } from "./Data";
import Testimonial from "../../components/Testimonials/Testimonials";
import Blogs from "../../components/Blogs/Blogs";
import IdvServicesTabs from "../../components/IdvServicesTabs/IdvServicesTabs";
import FAQs from "../../components/FAQs/FAQs";
import { TabData } from "./TabData";

const FamilyVisa = () => {
  return (
    <>
      <Hero
        span="FAMILY"
        heading={"SPONSORSHIP"}
        img={"/images/family-hero.webp"}
        mobileImg={"/images/family-mob-hero.webp"}
      />
      <ImgByContent
        gradientSpecific={
          "linear-gradient(to bottom, #080707, #080707, #121315)"
        }
        reverse={true}
        span={"FAMILY"}
        heading={"SPONSORSHIP"}
        img={"/images/spousal-visa2.webp"}
        text={`Do you aspire for your loved ones to be beside you in Canada? Family sponsorship stands as a cherished immigration program in Canada, offering Canadian citizens and permanent residents the opportunity to bring their dear ones closer. This program is a beacon of hope for families longing to reunite and flourish on Canadian soil. However, delving into the family sponsorship process requires a keen understanding of vital components like eligibility criteria, sponsorship categories, financial requisites, and processing times, among others. With the intricacies involved, the guidance of a proficient immigration consultant is indispensable. At Lodestone Immigration, we're committed to navigating you seamlessly through these complexities, ensuring you and your family embrace a bright future in Canada together.`}
      />
      <IdvServicesTabs Data={TabData} />
      <Awards />
      <FAQs text={"Family Sponsorship"} Data={Data} />
      <Testimonial />
      <Blogs />
      <Footer />
    </>
  );
};

export default FamilyVisa;
