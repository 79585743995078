import React, { useState } from "react";
import axios from "axios";
import "./Contactform.css";
import { Container, Row, Spinner } from "react-bootstrap";

const Contactform = ({ heading, span, text, seoAnalysis, TopSpace = true }) => {
  const [selectedProject, setSelectedProject] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("/send-contact-form", {
        name: name,
        email: email,
        message: "Project Discussion",
        phoneNum: phoneNum,
        project: selectedProject,
      })
      .then((response) => {
        if (response.data.msg === "success") {
          setError(false);
          setSuccess("Message Sent Successfully.");
          setLoading(false);
          setSelectedProject("");
          setName("");
          setEmail("");
          setPhoneNum("");
        } else if (response.data.msg === "fail") {
          setSuccess(false);
          setError("Message failed to send, try again by reloading the page.");
          setLoading(false);
          setSelectedProject("");
          setName("");
          setEmail("");
          setPhoneNum("");
        }
      });
  };

  return (
    <div
      style={{ backgroundImage: `url(/images/contactformbackground.jpg)` }}
      className="contact_section"
    >
      <Container>
        <Row className="gy-4 gx-lg-5">
          <div className="col-lg-5 col-md-12">
            <div className="contact-details">
              <h2 className="h2_main mb-4">
                Need A Consultation For Visa Or Migration Services?
              </h2>
              <p className="para_main">
                Let’s discuss your case and find out what we can do
                to provide value.
              </p>
            </div>
          </div>
          <div className="col-lg-7 col-md-12">
            <div className="row gy-4">
              <div className="col-12">
                <p className="para_main pb-3">
                  Fill out the form below and we will get back to you
                </p>
              </div>
              <div className="col-12">
                <form onSubmit={formSubmitHandler}>
                  <div className="row gy-4">
                    <div className="col-lg-6 col-md-6 col-12">
                      <input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        maxLength="36"
                        required
                        type="text"
                        placeholder="Your Full Name"
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <input
                        value={phoneNum}
                        onChange={(e) => setPhoneNum(e.target.value)}
                        required
                        maxLength="24"
                        type="text"
                        placeholder="Your Phone Number"
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        maxLength="64"
                        type="email"
                        placeholder="Your Email Address"
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <input
                        value={selectedProject}
                        onChange={(e) => setSelectedProject(e.target.value)}
                        required
                        maxLength="124"
                        type="text"
                        placeholder="Enter Your Enquiry"
                      />
                    </div>
                  </div>
                  {loading ? (
                    <div className="row mt-3">
                      <div className="col-12">
                        <p>
                          <Spinner
                            animation="border"
                            role="status"
                            style={{ color: "white" }}
                          />
                        </p>
                      </div>
                    </div>
                  ) : success ? (
                    <div className="row mt-3">
                      <div className="col-12">
                        <p className="para_main">{success}</p>
                      </div>
                    </div>
                  ) : (
                    error && (
                      <div className="row mt-3">
                        <div className="col-12">
                          <p className="para_main">{error}</p>
                        </div>
                      </div>
                    )
                  )}
                  <div className="row mt-5">
                    <div className="col-12 button_col">
                      <button type="submit">Submit</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Contactform;
