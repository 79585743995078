export const Data = [
  {
    id: "1",
    question: "What services does Lodestone Immigration offer?",
    answer: `Lodestone Immigration provides a comprehensive suite of immigration services, including assistance with Permanent Residence applications, work and study permits, family sponsorship, Canadian citizenship applications, and addressing refusals or procedural fairness letters. We also offer Commissioner of Oaths services for document authentication.    `,
    showAns: false,
  },
  {
    id: "2",
    question: "How experienced are the consultants at Lodestone Immigration?",
    answer: `Our consultants are highly experienced professionals who are registered and in good standing with the College of Immigration and Citizenship Consultants (CICC). They bring years of expertise and a track record of success to your immigration journey.`,
    showAns: false,
  },
  {
    id: "3",
    question:
      "How can Lodestone Immigration help with my immigration application?",
    answer: `Our expert consultants can assess your eligibility, guide you through the application process, ensure all documentation is correct and complete, and represent you in communications with Canadian immigration authorities.`,
    showAns: false,
  },
  {
    id: "4",
    question: "Can Lodestone Immigration speed up my application process?",
    answer: `While we cannot influence the processing times set by the Canadian immigration authorities, we ensure that your application is complete, accurate, and submitted in the best possible way to avoid any unnecessary delays.`,
    showAns: false,
  },
  {
    id: "5",
    question:
      "What makes Lodestone Immigration different from other immigration consultancies?",
    answer: `Lodestone Immigration is distinguished by our personalized approach, in-depth knowledge of Canadian immigration law, and our commitment to providing clear, honest, and ethical advice to our clients.`,
    showAns: false,
  },
  {
    id: "6",
    question:
      "What is the success rate of applications processed by Lodestone Immigration?",
    answer: `We pride ourselves on maintaining a high success rate, which is a testament to our diligent and tailored approach to each application. However, as each case is unique, we can discuss specific success rates in the context of your situation during a consultatio.`,
    showAns: false,
  },
  {
    id: "7",
    question:
      "Can Lodestone Immigration guarantee the success of my application?",
    answer: `While we cannot guarantee the outcome of any immigration application, we pledge to apply our extensive knowledge and experience to increase the likelihood of a successful result.`,
    showAns: false,
  },
  {
    id: "8",
    question: "How long does the immigration process usually take?",
    answer: `Processing times vary widely depending on the type of application and current processing times of the IRCC. We stay updated on all timelines to give you the most accurate information possible.`,
    showAns: false,
  },
  {
    id: "9",
    question: "What are the fees for Lodestone Immigration's services?",
    answer: `Our fees are competitive and vary depending on the complexity and type of immigration service you require. We provide a transparent fee structure upfront, so there are no surprises.`,
    showAns: false,
  },
  {
    id: "10",
    question:
      "Can I work in Canada while my Permanent Residence application is being processed?",
    answer: `You may be eligible to work in Canada while waiting for PR if you have a valid work permit. Our consultants can advise on your specific situation and help secure the appropriate authorization.`,
    showAns: false,
  },
  {
    id: "11",
    question:
      "How do I get started with my immigration process with Lodestone Immigration?",
    answer: `The first step is to book a consultation through our website or by contacting us directly. We'll discuss your needs, explain your options, and outline the next steps.`,
    showAns: false,
  },
  {
    id: "12",
    question: "What should I expect during the consultation?",
    answer: `During the consultation, we will assess your immigration needs, answer any questions you have, and provide you with a clear understanding of the potential pathways and strategies for your immigration process.`,
    showAns: false,
  },
  {
    id: "13",
    question: "How do I know which immigration program is right for me?",
    answer: `Our experts will help determine the most suitable immigration program for you based on your individual circumstances, goals, and the latest Canadian immigration criteria.`,
    showAns: false,
  },
  {
    id: "14",
    question: "What documents do I need for my immigration application?",
    answer: `The documents required will vary depending on the type of application. Generally, you'll need proof of identity, educational and professional credentials, language test results, and other personal documents. We'll provide you with a checklist called "Document Tree"`,
    showAns: false,
  },
  {
    id: "15",
    question: "Does Lodestone Immigration help with visa refusals and appeals?",
    answer: `Yes, we offer services to review your case if your visa has been refused, provide advice on potential grounds for appeal, and assist with the submission of an appeal or reapplication.`,
    showAns: false,
  },
  {
    id: "16",
    question: "Is my personal information safe with Lodestone Immigration?",
    answer: `Absolutely. We adhere to strict confidentiality agreements and privacy laws, ensuring your information is secure and used solely for your immigration process.    `,
    showAns: false,
  },
  {
    id: "17",
    question:
      "Can I contact Lodestone Immigration for follow-up questions after my initial consultation?",
    answer: `Absolutely. We encourage clients to reach out with any follow-up questions or concerns. We are here to support you throughout your entire immigration process.`,
    showAns: false,
  },
  {
    id: "18",
    question: "How can I contact Lodestone Immigration for a consultation?",
    answer: `You can reach out to us via the contact form on our website, email, or phone. We'll respond promptly to schedule a consultation at your convenience.`,
    showAns: false,
  },
];
