import React, { Fragment, useEffect } from "react";
import ImgContent from "./ImgContent/ImgContent";
import NextCaseStudies from "./NextCaseStudies/NextCaseStudies";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { listCaseStudyDetails } from "../../store/actions/caseStudyActions";
import { Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Hero from "../../components/Hero/Hero";
import Footer from "../../components/Footer/Footer";
import Contactform from "../../components/Contact Form/Contactform";

const IdvCaseStudy = () => {
  const params = useParams();
  const id = params.id;
  const dispatch = useDispatch();

  const caseStudyDetails = useSelector((state) => state.caseStudyDetails);
  const { error, loading, caseStudy } = caseStudyDetails;

  useEffect(() => {
    dispatch({ type: "CASESTUDY_DETAILS_RESET" });
    dispatch(listCaseStudyDetails(id));
  }, [dispatch, id]);

  return (
    <Fragment>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      {loading || !caseStudy ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "50px",
            paddingBottom: "50px",
          }}
        >
          <Spinner
            style={{
              color: "red",
              width: "120px",
              height: "120px",
              alignSelf: "center",
              textAlign: "center",
            }}
          />
        </div>
      ) : error ? (
        <p className="para_main" style={{ color: "red" }}>
          {error}
        </p>
      ) : (
        caseStudy &&
        !loading &&
        !error && (
          <>
            <div style={{ backgroundColor: "#080707" }}>
              <Hero
                span={caseStudy.heroHeading}
                text={`Hear the success stories about some of our clients. A big thank you to those who choose us.
               You never know, your success story could be next.`}
                blog={true}
                IdvCaseStudy={true}
                img={"/images/hero-case-studies.webp"}
                mobileImg={"/images/hero-case-studies-mob.webp"}
              />
              <ImgContent
                heading={caseStudy.sec1Heading}
                para={caseStudy.sec1Para}
                para2={caseStudy.sec1Para2}
                img={caseStudy.sec1Img}
                heading2={caseStudy.sec2Heading}
                para3={caseStudy.sec2Para}
                heading3={caseStudy.sec3Heading}
                para4={caseStudy.sec3Para}
                heading4={caseStudy.sec4Heading}
                para5={caseStudy.sec4Para}
                heading5={caseStudy.sec5Heading}
                para6={caseStudy.sec5Para}
                heading6={caseStudy.sec6Heading}
                para7={caseStudy.sec6Para}
                heading7={caseStudy.sec7Heading}
                para8={caseStudy.sec7Para}
                heading8={caseStudy.sec8Heading}
                para9={caseStudy.sec8Para}
                heading9={caseStudy.sec9Heading}
                para10={caseStudy.sec9Para}
              />
            </div>
          </>
        )
      )}
      {!loading && <NextCaseStudies />}
      <Contactform />
      <Footer />
    </Fragment>
  );
};

export default IdvCaseStudy;
