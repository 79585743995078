import React from "react";
import ImgByContent from "../../components/ImgByContent/ImgByContent";
import Hero from "../../components/Hero/Hero";
import Footer from "../../components/Footer/Footer";
import Awards from "../../components/Awards/Awards";
import { Data } from "./Data";
import Testimonial from "../../components/Testimonials/Testimonials";
import Blogs from "../../components/Blogs/Blogs";
import IdvServicesTabs from "../../components/IdvServicesTabs/IdvServicesTabs";
import FAQs from "../../components/FAQs/FAQs";
import { TabData } from "./TabData";

const Work = () => {
  return (
    <>
      <Hero
        span="WORK"
        heading={"IN CANADA"}
        img={"/images/work-hero.webp"}
        mobileImg={"/images/work-mob-hero.webp"}
      />
      <ImgByContent
        gradientSpecific={
          "linear-gradient(to bottom, #080707, #080707, #121315)"
        }
        reverse={true}
        span={"WORK "}
        heading={"IN CANADA"}
        img={"/images/work-permit2.webp"}
        text={`Canada, with its booming economy and diverse sectors, stands as a beacon for global talent seeking promising career opportunities. The nation's inclusive work culture, competitive compensation, and progressive employment laws make it a top destination for professionals worldwide. However, navigating the Canadian work visa process can be intricate. Whether you're aiming for a temporary work stint or envisioning long-term employment, understanding the requirements and eligibility is pivotal. At Lodestone Immigration, we simplify this journey for you. Our team of dedicated experts will guide you through the nuances of securing the right work permit, ensuring you're well-positioned to tap into Canada's dynamic job market. Let's transform your Canadian work dream into reality        `}
      />
      <IdvServicesTabs Data={TabData} />
      <Awards />
      <FAQs text={"Work In Canada"} Data={Data} />
      <Testimonial />
      <Blogs />
      <Footer />
    </>
  );
};

export default Work;
